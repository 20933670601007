<template>
<div id="spinner-container">
    <div id="spinner">
        <spinner></spinner>
    </div>
</div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
    components: {
        Spinner
    }
}
</script>

<style lang="scss" scoped>
#spinner-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(136, 136, 136, 0.5);
    z-index: 9;
}

#spinner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}
</style>

