<template>
<div>
    <spinner v-if="processing"></spinner>
    <page-heading>
        Which stakeholder?
    </page-heading>
    
    <stakeholder-search 
        @searchStakeholders="searchStakeholders" 
        @selectStakeholder="selectStakeholder"
        @createStakeholder="createStakeholder"
    >
    </stakeholder-search>

    <b-row align-h="center">
        <b-col xs="12" md="10" lg="10" xl="10">
            <div class="hr-sect">OR</div>
        </b-col>
    </b-row>

    <b-row align-h="center" class="mt-2">
        <b-col xs="12" md="10" lg="10" xl="10">
            <card-button
            title="Find stakeholders on map"
            :onClick="()=>{$router.push('/doorknock/map')}"
            >
            </card-button>
        </b-col>
    </b-row>

    <footer-button v-if="!processing"
    title="Create new"
    :onClick="createStakeholder"
    >
    </footer-button>
</div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import StakeholderSearch from '@/components/StakeholderSearch.vue';
export default {
    components: {
        'spinner': Spinner,
        'stakeholder-search': StakeholderSearch
    },
    methods: {
        searchStakeholders(searchParams) {
            this.processing = true;
            this.$store.dispatch('getStakeholdersBySearchParams', searchParams.params).then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                this.$router.push({ 
                    name: 'stakeholder-select', 
                    params: { 
                        ...searchParams.params,
                        address: searchParams.address,
                        nextUrl: this.useRMS ? {
                            name:'stakeholder-edit'
                        } :
                        {
                            name: 'engagement-edit',
                            params: {
                                nextUrl: {
                                    path: '/doorknock/extra'
                                }
                            }
                        }
                    }
                });
            }).finally(() => {
                this.processing = false;
            });
        },
        createStakeholder(params) {
            this.setStakeholder({});
            this.$router.push({
                name: 'stakeholder-edit',
                params: {
                    prefilled: params
                }
            });
        },
        selectStakeholder(stakeholder) {
            this.setStakeholder(stakeholder);

            if (this.useRMS || !this.stakeholder.id) {
                this.$router.push({
                    name: 'stakeholder-edit'
                });
            }          
            else if (this.stakeholder.id) {
                this.saveStakeholderAndEngagement();
            }
        }
    },
    created() {
        this.$store.commit('resetEngagement', {});
        this.$store.commit('setEngagementEvent', this.communityEvent.id);
        this.$store.commit('resetStakeholder');
        this.$store.commit('setEngagementMethod', this.DOORKNOCK_METHOD.method)
    }
}
</script>
