<template>
<div>
    <b-container class="wrapper-page">
        <div class="text-center">
            <img srcset="../assets/ca-logo-2021-640x256.png 2x" src="../assets/ca-logo-2021-320x128.png" width="320" height="128" alt="Community Analytics" class="img-responsive" style="margin: 0 auto">
            <b-row align-h="center">
                <b-col sm="12" md="12" lg="10" xl="10">
                    <h1 class="error-icon"><i class="fa fa-exclamation-triangle"></i></h1>
                    <h1>We're sorry, but something went wrong.</h1>
                    <h4>This issue has been logged and the team is working on it. Please try again later, or
                    <a href="mailto:contact@spatialmedia.com.au">contact support</a> if error persists.</h4>
                </b-col>
                <b-col sm="12" md="12" lg="10" xl="10">
                    <card-button title="Go back" variant="primary" :onClick="() => {$router.go(-1)}"></card-button>
                </b-col>
                <b-col sm="12" md="12" lg="10" xl="10">
                    <card-button title="Back to home" variant="secondary" :onClick="() => {$router.push('/')}"></card-button>
                </b-col>
            </b-row>
        </div>
    </b-container>
</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.wrapper-page {
    padding-top: 7.5%;
    padding-bottom: 10px;
    margin: 0 auto;
}

.img-responsive {
    display: block;
    max-width: 360px;
    height: auto;
}

.dark {
    background: #181f2f;
    color: #FFF;
    min-height: 100%;
}
</style>
