<template>
<div class="text-left pt-4 pb-5">
    <b-row align-h="center">
        <b-col sm="12" md="10" lg="10" xl="10" class="border-bottom">
            <h6 @click.prevent="toggle" class="summary-title float-left">{{title}}</h6>
            <a @click.prevent="toggle" class="float-right">
                <span class="anchor">{{visible ? '&#9650;' : '&#9660;'}}</span>
            </a>
        </b-col>
    </b-row>
    <b-collapse id="stakeholder-summary" v-model="visible" class="mt-2">
        <b-row align-h="center">
            <b-col sm="12" md="10" lg="5" xl="5">
                <h6 class="details-header">
                    <span>{{stakeholderName}}</span>
                    <span v-if="stakeholderCount > 0">+{{stakeholderCount}}</span>
                </h6>
                <div class="details-block">
                    <p v-if="stakeholder.email">
                        <strong>Email: </strong>{{stakeholder.email}}
                    </p>
                    <p v-if="stakeholder.home_phone || stakeholder.mobile_phone">
                        <strong>Phone: </strong>{{stakeholder.home_phone ? stakeholder.home_phone : stakeholder.mobile_phone}}
                    </p>
                    <p v-if="stakeholderAddress">
                        <strong>Address: </strong>{{stakeholderAddress}}
                    </p>
                    <p><router-link :to="stakeholderEditUrl">Edit</router-link></p>
                </div>
            </b-col>
            <b-col sm="12" md="10" lg="5" xl="5">
                <h6 class="details-header">{{engagement.method}}</h6>
                <div class="details-block">
                    <p v-if="engagement.outcome">
                        <strong>Outcome: </strong>{{engagement.outcome}}
                    </p>
                    <p v-if="engagement.sentiment_label">
                        <strong>Sentiment: </strong>{{engagement.sentiment_label}}
                    </p>
                    <p v-if="engagement.name">
                        <strong>Summary: </strong>{{engagement.name}}
                    </p>
                    <p v-if="engagement.notes">
                        <strong>Stakeholder comments: </strong>{{engagement.notes}}
                    </p>
                    <p v-if="engagement.team_response">
                        <strong>Team response: </strong>{{engagement.team_response}}
                    </p>
                    <p><router-link :to="engagementEditUrl">Edit</router-link></p>
                </div>
            </b-col>
        </b-row>
    </b-collapse>
</div>
</template>

<script>
export default {
    props: {
        title: String,
        value: {
            type: Boolean,
            default: false
        },
        stakeholderEditUrl: String,
        engagementEditUrl: String,
        stakeholderCount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            visible: this.value
        }
    },
    computed: {
        stakeholderName() {
            return this.stakeholderToFullName(this.stakeholder)
        }
    },
    methods: {
        hide() {
            this.visible = false;
            this.$emit('input', this.visible);
        },
        show() {
            this.visible = true;
            this.$emit('input', this.visible);
        },
        toggle() {
            this.visible = !this.visible;
            this.$emit('input', this.visible);
        }
    },
    watch: {
        value: function(newVal){
            this.visible = newVal;
        }
    }

}
</script>

<style lang="scss" scoped>
.summary-title {
    cursor: pointer;
}
.details-block {
    width: 100%;
    border-left: 2px solid #bbc0c4;
    padding-left: 8px;
    font-size: 0.8rem;
}
.anchor {
    color: #bbc0c4;
}
.details-header {
    font-weight: bold;
    clear:both;
    display: flex;
    justify-content: space-between;
}
.details-block p {
    margin-bottom: 0.5rem;
}
</style>
