<script>
import EventSelector from '@/components/EventSelector'
export default {
    extends: EventSelector,
    methods: {
        selectEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            if (event.form_id) {
                this.$store.dispatch('getForm', event.form_id);
            }
            this.$router.push('/doorknock/counter');
        },
        editEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            this.$router.push({ 
                name: 'event-edit', 
                params: { 
                    nextUrl: {
                        path: '/doorknock',
                    }
                }
            });
        },
        createEvent() {
            this.$router.push({ 
                name: 'event-create', 
                params: { 
                    nextUrl: {
                        path: '/doorknock/counter',
                    }
                }
            });
        }
    },
    created() {
        this.$store.commit('resetStore');

        this.processing = true;
        this.$store.commit('setEngagementMethod', this.DOORKNOCK_METHOD.method);
        this.eventName = this.DOORKNOCK_METHOD.text + ' event';
        this.nameUsesAn = false;
        this.$store.dispatch('getTodaysEvents', this.DOORKNOCK_METHOD.method).finally(() => {
            this.processing = false;
        })
    }
}
</script>