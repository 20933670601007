<template>
<div>
    <b-row v-if="items.length" align-h="center">
        <b-col :sm="sm" :md="md" :lg="lg" :xl="xl" v-for="item in visibleItems" :key="item[keyName]">
            <b-card no-body @click.prevent="selectIfNotDisabled(item)" class="text-center my-2 card-button" v-bind:class="{
                'card-button': !useEditLink && !usePublicMode,
                'card-button-with-edit' : useEditLink || usePublicMode,
            }">
                <div class="card-body text-light" v-bind:class="{
                    'bg-secondary':!disableItem(item),
                    'bg-dark':disableItem(item),
                    'card-button-disabled':disableItem(item)
                }">
                    <slot v-bind="item">

                    </slot>
                </div>
            </b-card>
            <b-row align-h="between">
                <b-col cols="4">
                    <button class="edit-button bg-secondary" v-if="usePublicMode" href="" @click.prevent="publicSignIn(item)">Public Sign in</button>
                </b-col>
                <b-col cols="4">
                    <button class="edit-button bg-primary" v-if="useEditLink" href="" @click.prevent="editItem(item)">Edit</button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
export default {
    props: {
        items: Array,
        keyName: String,
        selectItem: Function,
        disableItem: {
            type: Function,
            default: () => {
                return false;
            }
        },
        sizing: {
            type: Object,
            default: () => {
                return {}
            }
        },
        useEditLink: {
            type: Boolean,
            default: false
        },
        usePublicMode: {
            type: Boolean,
            default: false
        },
        editItem: Function,
        publicSignIn: Function,
    },
    methods: {
        selectIfNotDisabled(item) {
            if (!this.disableItem(item)) {
                this.selectItem(item);
            }
        }
    },
    computed: {
        visibleItems() {
            return this.items.filter((item) => {
                return !this.disableItem(item);
            });
        },
        sm() {
            if(typeof this.sizing.sm !== 'undefined' && this.sizing.sm !== null) {
                return this.sizing.sm;
            }
            else {
                return 12;
            }
        },
        md() {
            if(typeof this.sizing.md !== 'undefined' && this.sizing.md !== null) {
                return this.sizing.md;
            }
            else {
                return 10;
            }
        },
        lg() {
            if(typeof this.sizing.lg !== 'undefined' && this.sizing.lg !== null) {
                return this.sizing.lg;
            }
            else {
                return this.visibleItems.length % 2 === 0 ? 5 : 10;
            }
        },
        xl() {
            if(typeof this.sizing.xl !== 'undefined' && this.sizing.xl !== null) {
                return this.sizing.xl;
            }
            else {
                return this.visibleItems.length % 2 === 0 ? 5 : 10;
            }
        }
    }
}
</script>

<style scoped>
.edit-button {
    width: 100%;
}
.card-button {
    height: calc(100% - 16px);
    border: none;
}
.card-button-with-edit {
    height: calc(100% - 57px);
}
.card-body {
    padding: 0.75rem;
}
.card-button-disabled {
    opacity: 0.7;
}
.card-body h4 {
    user-select: none;
}
.card-body h4, .card-body p {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
.card-body p {
    font-size: 0.7rem;
}
.card-body.bg-primary:not(.card-button-disabled):hover,.card-body.bg-primary:not(.card-button-disabled):focus {
    background-color: #1a8a58 !important;
}
.card-body.bg-secondary:not(.card-button-disabled):hover,.card-body.bg-secondary:not(.card-button-disabled):focus {
    background-color: #0062cc !important;
}
</style>
