<template>
<div class="footer-button">
    <b-container>
        <b-row align-h="center">
            <b-col sm="12" md="10" lg="10" xl="10">
                <card-button
                :title="title"
                :variant="variant"
                :disabled="disabled"
                :onClick="onClick"
                :linkAddress="linkAddress"
                :openLinkInNewTab="openLinkInNewTab">
                    <slot></slot>
                </card-button>
                <!--<b-button block size="lg" @click="onClick" variant="primary">{{title}}</b-button>-->
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default({
    props: {
        title: String,
        variant: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: () => {}
        },
        linkAddress: {
            type: String,
            default: ""
        },
        openLinkInNewTab: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        clickIfNotDisabled() {
            if (!this.disabled) {
                this.onClick();
            }
        }
    },
});
</script>


<style lang="scss">
.page{
    padding-bottom: 80px;
}
.footer-button {
    position: fixed;
    bottom: 0px;
    left:0;
    right:0;
    border-top: 2px solid #dee2e6;
    background-color: #ffffff;
    z-index: 8;
}
.card-button {
    height: calc(100% - 16px);
}
.card-body {
    padding: 0.75rem;
}
.card-body h4 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
</style>
