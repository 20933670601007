
<script>
import EventEditor from '@/components/EventEditor.vue';
import _ from 'lodash';
export default {
    extends: EventEditor,
    methods: {
        saveEvent() {
            this.processing = true;
            this.$store.commit('setEvent', this.localEvent);
            this.$store.dispatch('saveEvent').then((event) => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                if (Object.keys(this.nextUrlObj).length) {
                    this.$router.push(this.nextUrlObj);
                }
                if (event.data.form_id) {
                    this.$store.dispatch('getForm', event.data.form_id);
                }
            }).finally(() => {
                this.processing = false;
            });
        },
    },
    created() {
        this.title = "Edit " + this.communityEvent.name;
        this.localEvent = _.clone(this.communityEvent);
        this.startDate = this.communityEvent.start;
        this.endDate = this.communityEvent.end;
        this.address = this.addressFromEvent(this.localEvent);
        if (this.addressToString(this.address).length) {
            this.showLocation = true;
            this.showLocationFields = true;
        }
        if (Object.keys(this.nextUrlObj).length) {
            this.$store.commit('setNextUrl', this.nextUrlObj);
        }
        else if (this.$store.state.nextUrl){
            this.nextUrlObj = this.$store.state.nextUrl;
        }
        if (this.useSydMetroWest) {
            this.localEvent.type = this.$store.state.engagement.method;
            this.typesDisabled = true;
        }
        if (this.engagement.method == this.DOORKNOCK_METHOD.method) {
            this.localEvent.type = this.engagement.method;
            this.typesDisabled = true;
        }
    }
}

</script>
