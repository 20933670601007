<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>Edit {{engagementMethodTitle}} engagement notes</page-heading>

        <b-row v-if="areDoorknocking" align-h="center">
            <b-col sm="10">
                <b-form-group label="Outcome">
                    <multiselect class="my-2" id="outcome" 
                        v-model="localEngagement.outcome"
                        :options="outcomes"
                        :searchable="false"
                        :close-on-select="true"
                        placeholder="Doorknock outcome"
                    ></multiselect>
                </b-form-group>
            </b-col>
        </b-row>

        <!--<b-row v-if="areDoorknocking" align-h="center">
            <b-col sm="10">
                <b-form-group label="Topics">
                    <multiselect class="my-2" id="topics" 
                        v-model="categories"
                        :options="$config.issues"
                        :multiple="true"
                        label="text"
                        track-by="text"
                        :searchable="false"
                        :close-on-select="true"
                        placeholder="Discussed topics"
                    ></multiselect>
                </b-form-group>
            </b-col>
        </b-row>-->

        <b-row align-h="center">
            <b-col sm="10">
                <label for="summary">Summary</label>
                <b-form-textarea
                    id="summary"
                    v-model="localEngagement.name"
                    :placeholder="summaryPlaceholder"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col sm="10">
                <label for="stakeholder-comments">Stakeholder comments</label>
                <b-form-textarea
                    id="stakeholder-comments"
                    v-model="localEngagement.notes"
                    placeholder="Enter stakeholder comments here"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col sm="10">
                <label for="team-response">Team response</label>
                <b-form-textarea
                    id="team-response"
                    v-model="localEngagement.team_response"
                    placeholder="Enter team response here"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col sm="10">
                <b-form-group label="Sentiment">
                    <b-form-radio-group
                        id="btn-followup"
                        v-model="sentiment"
                        :options="sentiments"
                        buttons
                        stacked
                        button-variant="outline-primary"
                        size="lg"
                        name="radio-btn-outline"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col sm="10">
                <b-form-group label="Follow up required">
                    <b-form-radio-group
                        id="btn-followup"
                        v-model="localEngagement.requires_followup"
                        :options="[
                            { text: 'No', value: false },
                            { text: 'Yes', value: true }
                        ]"
                        buttons
                        button-variant="outline-primary"
                        size="lg"
                        name="radio-btn-outline"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>

        <!--<b-row align-h="center">
            <b-col sm="8">
                <b-button size="lg" block variant="primary" @click="saveEngagement">Continue</b-button>
            </b-col>
        </b-row>-->

        <footer-button
        title="Continue"
        :onClick="saveEngagement"
        >
        </footer-button>
    </b-container>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
export default {
    components: {
        'spinner': Spinner,
        'multiselect': Multiselect
    },
    props: {
        nextUrl: {
            type: Object,
            default: (() => {
                return {};
            })
        },
        prefilled: {
            type: Object,
            default: (() => {
                return {};
            })
        }
    },
    data() {
        return {
            localEngagement: {},
            sentiment: {},
            outcomes: [
                'Not home / unable to access',
                'Home, not interested',
                'Home, interested',
            ],
            categories: []
        }
    },
    computed: {
        engagementMethodTitle() {
            if (this.engagement.method == this.DOORKNOCK_METHOD.method) {
                return this.DOORKNOCK_METHOD.text.toLowerCase();
            }
            if (this.engagement.method == this.EVENT_FEEDBACK_METHOD.method) {
                return this.EVENT_FEEDBACK_METHOD.text.toLowerCase();
            }
            if (this.engagement.method == this.PRESENTATION_METHOD.method) {
                return this.PRESENTATION_METHOD.text.toLowerCase();
            }
            if (this.engagement.method == this.COMMUNITY_INFORMATION_SESSION_METHOD.method) {
                return this.COMMUNITY_INFORMATION_SESSION_METHOD.text.toLowerCase();
            }
            if (this.engagement.method == this.PHONE_METHOD.method) {
                return this.PHONE_METHOD.text.toLowerCase();
            }
            return '';
         },
         summaryPlaceholder() {
            if (this.engagement.method === this.EVENT_FEEDBACK_METHOD.method) {
                return 'Attended event';
            }
            else {
                return 'Enter summary notes about this engagement here';
            }
        },
        sentiments() {
            return this.getSentiments(this.useSydMetroWest).map((sentiment) => {
                return {
                    text: sentiment.text,
                    value: {
                        label: sentiment.label,
                        value: sentiment.value
                    }
                }
            });
        },
    },
    methods: {
        saveEngagement() {
            this.processing = true;
            this.$store.commit('setEngagement', this.localEngagement);
            this.$store.dispatch('saveEngagement').then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                if (Object.keys(this.nextUrl).length) {
                    this.$router.push(this.nextUrl);
                }
                else if (this.engagement.method == this.DOORKNOCK_METHOD.method) {
                    this.$router.push('/doorknock/extra');
                }
                else if (this.engagement.method == this.EVENT_FEEDBACK_METHOD.method ||
                    this.engagement.method == this.PRESENTATION_METHOD.method ||
                    this.engagement.method == this.COMMUNITY_INFORMATION_SESSION_METHOD.method) {
                        
                    this.$router.push('/eventfeedback/extra');
                }
                else if (this.engagement.method == this.PHONE_METHOD.method) {
                    this.$router.push('/phonecall/extra');
                }
            }).finally(() => {
                this.processing = false;
            });
        },
        setCategories(categoryStrings) {
            this.categories = [];

            if (!categoryStrings) {
                return;
            }

            categoryStrings.split(',').forEach((id) => {
                this.$config.issues.some((issue) => {
                    if (issue.id == id) {
                        this.categories.push(issue);
                        return true;
                    }
                });
            });
        }
    },
    created() {
        if (Object.keys(this.prefilled).length) {
            Object.assign(this.localEngagement, this.prefilled);
        }
        else {
            this.localEngagement = _.clone(this.engagement);
        }
        this.sentiment = {
            label: this.localEngagement.sentiment_label,
            value: this.localEngagement.sentiment
        }
        this.setCategories(this.localEngagement.category)
    },
    watch: {
        "sentiment": function(newVal) {
            this.localEngagement.sentiment = newVal.value;
            this.localEngagement.sentiment_label = newVal.label;
        },
        "categories": function(newVal) {
            if (!newVal.length) {
                this.localEngagement.category = null;
                return;
            }
            this.localEngagement.category = newVal.map((category) => {
                return category.id;
            }).join();
        }
    }
}

</script>
