<template>
    <div>
        <app-navigation></app-navigation>
        <div class="page">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import AppNavigation from '@/components/AppNavigation';

    export default {
        name: 'layout',
        components: {
            AppNavigation,
        },
        created() {
            document.title = "CA Canvass | " + this.$store.state.client.name +
                " | " + this.$store.state.project.name;
        }
    }
</script>


