<template>
    <div class="doorknock-location">

        <spinner v-if="processing"></spinner>

        <page-heading :displayBack="true">
            Doorknocking: Where are you? <template v-if="loadingGeoLocation">(Loading Geolocation)</template>
        </page-heading>

        <b-alert :show="showWarning" variant="warning">
            We can't find your position, have you given google maps permission to access it?  Try enabling it <a href="https://support.google.com/maps/answer/1250066?hl=en&visit_id=636977116311424485-2739295914&rd=1">here</a>
        </b-alert>

        <form @submit.prevent="goToSearchAddress()" class="form-horizontal" action="#">
            <b-row align-h="center" align-v="center">
                    <b-col sm="12" md="12" lg="9" xl="9">
                        <!--<b-form-input id="location-search" placeholder="Search by address" sm="4" size="lg" v-model="searchAddressInput" trim></b-form-input>-->
                        <gmap-autocomplete
                            placeholder="Search by address" @place_changed="setSearchAddress" :selectFirstOnEnter="true" class="form-control form-control-lg"
                        >
                        </gmap-autocomplete>
                    </b-col>
                    <b-col sm="12" md="12" lg="3" xl="3">
                        <card-button :onClick="geolocate" :disabled="showWarning || loadingGeoLocation">My location</card-button>
                    </b-col>
            </b-row>
        </form>

        <stakeholder-map ref="map" @mapLoaded="geolocate" @locationChanged="updatePosition" @zoomChanged="zoomChanged"
            :initialCenter="{lat: -34.0404501,lng: 147.9291009}" :useDragMarker="true" 
            :initialZoomLevel="$config.initialMapZoomLevel" :activeZoomLevel="$config.activeMapZoomLevel" :markers="markers">
            <template slot="marker-window" slot-scope="marker">
                <p>
                    <strong>Name: </strong>{{stakeholderToFullName(marker.data)}}
                </p>
                <p v-if="marker.data.email">
                    <strong>Email: </strong>{{marker.data.email}}
                </p>
                <p v-if="marker.data.mobile_phone ? marker.data.mobile_phone : (marker.data.home_phone ? marker.data.home_phone : false)">
                    <strong>Phone: </strong>{{marker.data.mobile_phone ? marker.data.mobile_phone : marker.data.home_phone}}
                </p>
                <p v-if="addressToShortString(marker.data)">
                    <strong>Address: </strong>{{addressToShortString(marker.data)}}
                </p>
                <p v-if="marker.data.sentiment">
                    <strong>Sentiment: </strong>{{marker.data.sentiment.label}}
                </p>
                <card-button :onClick="() => {doorknockStakeholder(marker.data)}">
                    Doorknock this stakeholder
                </card-button>
            </template>
            <template slot="cluster-window" slot-scope="cluster">
                <p>Multiple stakeholders</p>
                <card-button :onClick="() => {doorknockCluster(cluster)}">
                    Continue
                </card-button>
            </template>
        </stakeholder-map>

        <b-row>
            <b-col>
                <p>Move the map to adjust the address.</p>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col sm="12" md="12" lg="6" xl="6">
                <h3 class="address" :class="{'address-set': addressSet}">{{ displayAddress }}</h3>
            </b-col>
            <b-col sm="6" md="6" lg="6" xl="6">
                <b-form-group
                    id="address-unit"
                    label="Unit / Apartment Number"
                    label-for="address-unit"
                    label-size="lg"
                >
                    <b-form-input sm="4" id="address-unit" size="lg" v-model="unit_number" trim></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center">
            <b-col xs="12" md="10" lg="10" xl="10">
                <card-button
                title="Manually search for stakeholder"
                :onClick="()=>{$router.push('/doorknock/search')}"
                >
                </card-button>
            </b-col>
        </b-row>

        <footer-button
        :disabled="!Object.keys(this.address).length"
        title="Confirm address"
        :onClick="submit"
        >
        </footer-button>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import StakeholderMap from '@/components/Map.vue';
export default {
    components: {
        'spinner': Spinner,
        'stakeholder-map': StakeholderMap
    },
    data() {
        return {
            autocomplete: null,
            address: {},
            addressSet: false,
            unit_number: "",
            markers: [],
            searchEnabled: false,
            searchAddress: null,
            loadingGeoLocation: true,
            showWarning: false,
            stakeholderLimit: 100
        }
    },
    created() {
        this.$store.commit('resetEngagement', {});
        this.$store.commit('setEngagementEvent', this.communityEvent.id);
        this.$store.commit('resetStakeholder');
        this.$store.commit('setEngagementMethod', this.DOORKNOCK_METHOD.method)
    },
    computed: {
        displayAddress: function() {
            if (this.address) {
                return this.address.formatted_address;
            }

            return null;
        }
    },
    methods: {
        updatePosition(position) {
            this.geocodeAddress(position).then(() => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                return this.$refs.map.getMapBounds()
            }).then((bounds) => {
                this.plotNearbyStakeholders(bounds);
            });
        },
        geocodeAddress(position) {
            return this.$refs.map.getGeocodedAddress(position.lat, position.lng).then((address) => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.address = address;
                this.addressSet = true;

                this.$store.commit('setStakeholderPosition', position);
                this.$store.commit('setStakeholderSearchPosition', position);
            });
        },
        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        geolocate : function() {
            return new Promise((resolve, reject) => {

                if (navigator.geolocation) {
                    if (this.$store.state.position) {
                        this.loadingGeoLocation = false;
                        this.$refs.map.setLocation({
                            lng: this.$store.state.position.coords.longitude,
                            lat: this.$store.state.position.coords.latitude
                        });
                        resolve();
                    }
                    else {
                        this.loadingGeoLocation = false;
                        this.showWarning = true;
                        if (this.$store.state.previousStakeholder && this.addressToString(this.$store.state.previousStakeholder)) {
                            let prevStakeholder = this.$store.state.previousStakeholder;
                            if (prevStakeholder.lat && prevStakeholder.lng) {
                                this.$refs.map.setLocation({
                                    lat: parseFloat(prevStakeholder.lat), 
                                    lng: parseFloat(prevStakeholder.lng)
                                });
                            }
                            else {
                                this.$refs.map.searchLocation(this.addressToString(prevStakeholder));
                            }
                        }
                        else if (this.$store.state.project.longitude && this.$store.state.project.latitude) {
                            this.$refs.map.setLocation({
                                lat: parseFloat(this.$store.state.project.latitude),
                                lng: parseFloat(this.$store.state.project.longitude)
                            });
                        }
                    }
                }
                else {
                    reject('Geolocator not available')
                } 
            }).catch((e) => {
                console.log(e);
            })
        },
        saveAddress() {
            let addressObj = this.parseGoogleMapsData(this.address);
            if (this.unit_number) {
                addressObj['address_unit_no'] = this.unit_number;
            }
            this.$store.commit('setStakeholder', addressObj);
            this.$store.commit('setSearchStakeholder', addressObj);
            this.$store.commit('setStakeholderAddress', this.address.formatted_address);
        },
        submit() {
            this.processing = true;
            this.saveAddress();
            this.$store.dispatch('getStakeholdersByAddress').then(() => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.$router.push('/stakeholder/select');
            }).finally(() => {
                this.processing = false;
            });
        },
        doorknockCluster(cluster) {
            const stakeholders = cluster.markers.map(marker => {
                return marker.data;
            });

            const position = {
                lat: cluster.position.lat(),
                lng: cluster.position.lng()
            }

            this.geocodeAddress(position).then(() => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.saveAddress();
                this.$store.commit('setPossibleStakeholders', stakeholders);
                this.$router.push('/stakeholder/select');
            });
        },
        doorknockStakeholder(stakeholder) {
            this.saveAddress();
            this.setStakeholder(stakeholder);
            this.$store.commit('setStakeholderAddress', this.address.formatted_address);
            if (this.useRMS) {
                this.$router.push('/stakeholder/edit')
            }
            else {
                this.saveStakeholderAndEngagement();
            }
        },
        plotNearbyStakeholders(position) {
            const request = {
                lng_ne: position.lng_ne,
                lng_sw: position.lng_sw,
                lat_ne: position.lat_ne,
                lat_sw: position.lat_sw,
                format: 'geojson',
                limit: this.stakeholderLimit
            };

            this.$http.get(this.$config.baseAPIUrl + this.$config.getStakeholdersUrl, {
                params: request
            }).then((response) => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.markers = [];
                response.data.data.forEach((stakeholder) => {
                    stakeholder.properties = this.flattenLocationModel(stakeholder.properties)
                    let marker = {
                        position: {
                            lng: stakeholder.geometry.coordinates[0],
                            lat: stakeholder.geometry.coordinates[1]
                        },
                        color: stakeholder.properties.sentiment ? stakeholder.properties.sentiment.color : null,
                        useInfoWindow: true,
                        data: stakeholder.properties
                    }

                    this.markers.push(marker);
                });
            });
        },
        zoomChanged() {
            this.$refs.map.getMapBounds().then((bounds) => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.plotNearbyStakeholders(bounds);
            });
        },
        setSearchAddress(searchAddress) {
            this.searchAddress = searchAddress;
            this.goToSearchAddress();
        },
        goToSearchAddress() {
            if (this.searchAddress && this.searchAddress.geometry) {
                const position = {
                    lat: this.searchAddress.geometry.location.lat(),
                    lng: this.searchAddress.geometry.location.lng()
                }

                this.$refs.map.setLocation(position);
            }
        }
    }
}

</script>

<style lang="scss" scoped>
@import "../variables.scss";
.doorknock-location {

    h2.address {
        margin: 1rem auto;
        width: 100%;
        opacity: 0;
        transition: opacity 2.0s ease;
        
        &.address-set {
            opacity: 1;
        }
    }
}
</style>
