<template>
<div>
    <form id="searchForm" action="#">
        <b-row align-h="center">
            <b-col xs="12" md="10" lg="10" xl="10">
                <multiselect class="my-2" v-model="searchStakeholder" id="stakeholders" 
                    label="display_name" track-by="id" 
                    placeholder="Search by email, name or phone" 
                    tag-placeholder="Press enter to select"
                    open-direction="bottom" 
                    :options="tempStakeholders" 
                    :multiple="false"
                    :searchable="true" 
                    :loading="searchProcessing" 
                    :internal-search="false" 
                    :clear-on-select="false" 
                    :close-on-select="true" 
                    :options-limit="300" 
                    :limit="10"
                    :limit-text="limitText"
                    :max-height="600" 
                    :show-no-results="true" 
                    :hide-selected="false" 
                    :preserve-search="true"
                    :showPointer="userCanHover"
                    selectLabel=""
                    @input="selectStakeholder"
                    @search-change="getStakeholders">
                        <template slot="option" slot-scope="props">
                            <b-row class="m-0">
                                <b-col xs="11">
                                    <p>
                                        <span v-if="props.option.first_name || props.option.last_name"
                                            v-html="highlightMatch(props.option.first_name + ' ' + props.option.last_name)"
                                        >
                                        </span>
                                        <span v-else-if="props.option.business_name"
                                            v-html="highlightMatch(props.option.business_name)"
                                        >
                                        </span>
                                        <span v-else class="text-missing">
                                            <em>Anonymous</em>
                                        </span>
                                        <span class="text-divider">
                                            |
                                        </span>
                                        <span v-if="props.option.email"
                                            v-html="highlightMatch(props.option.email)"
                                        >
                                        </span>
                                        <span v-else class="text-missing">
                                            <em>No email given</em>
                                        </span>
                                    </p>
                                    <p>
                                        <span v-if="props.option.phone || props.option.mobile_phone || props.option.home_phone"
                                            v-html="highlightMatch(props.option.phone ? props.option.phone : (props.option.mobile_phone ? props.option.mobile_phone : props.option.home_phone))"
                                        >
                                        </span>
                                        <span v-else class="text-missing">
                                            <em>No phone number given</em>
                                        </span>
                                        <span class="text-divider">
                                            |
                                        </span>
                                        <span v-if="addressToShortString(props.option)">
                                            {{addressToShortString(props.option)}}
                                        </span>
                                        <span v-else class="text-missing">
                                            <em>No address given</em>
                                        </span>
                                    </p>
                                </b-col>
                                <b-col xs="1">
                                    <span class="next-arrow">
                                        <font-awesome-icon :icon="['fas', 'arrow-right']"></font-awesome-icon>
                                    </span>
                                </b-col>
                            </b-row>
                        </template>
                        <template slot="noResult">
                            <p v-if="searchText.length >= minCharSearch" class="text-center">
                                Oops! No stakeholders found. Consider changing the search query.
                            </p>
                            <p v-else class="text-center">
                                Please enter at least {{minCharSearch}} characters.
                            </p>
                        </template>
                        <span slot="noOptions" class="text-center">
                            Please enter at least {{minCharSearch}} characters.
                        </span>
                        <template slot="beforeList">
                            <b-card v-if="tempStakeholders.length" no-body class="text-center">
                                <div class="card-body text-light bg-secondary">
                                    <h6>Tap a result below to finish {{this.engagementVerbPresent}} that stakeholder</h6>
                                </div>
                            </b-card>
                        </template>
                        <template slot="afterList">
                            <div @click.prevent="createStakeholder()" v-if="searchText.length >= minCharSearch || tempStakeholders.length || searchProcessing" class="text-center pointer">
                                <b-card xs="6" no-body class="text-center">
                                    <div class="card-body text-light bg-primary">
                                        <h6>Haven't found what you're looking for?</h6>
                                        <h4>Create new</h4>
                                    </div>
                                </b-card>
                            </div>
                        </template>
                </multiselect>
                <div class="hr-sect">OR</div>
                <gmap-autocomplete
                    placeholder="Search by address" @place_changed="getAddressData" :selectFirstOnEnter="true"
                    class="form-control" id="stakeholder-address"
                >
                </gmap-autocomplete>
            </b-col>
        </b-row>
    </form>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
    components: {
        'multiselect': Multiselect
    },
    data() {
        return {
            address: {},
            searchText: '',
            stakeholders: [],
            tempStakeholder: null,
            requestCancel: null,
            searchStakeholder: null,
            searchesRunning: 0,
            minCharSearch: 3,
            userCanHover: true
        }
    },
    computed: {
        searchProcessing() {
            return this.searchesRunning > 0;
        },
        tempStakeholders() {
            let tempStakeholders = [];
            if (this.tempStakeholder && JSON.stringify(this.tempStakeholder) == JSON.stringify(this.searchStakeholder)) {
                tempStakeholders.push(this.tempStakeholder);
            }
            return tempStakeholders.concat(this.stakeholders);
        },
    },
    methods: {
        searchStakeholders() {
            const searchParams = {
                ...this.address
            };
            Object.keys(this.address).forEach((key) => {
                searchParams[key] = this.address[key];
            });
            this.$emit('searchStakeholders', {
                params: searchParams,
                address: this.address
            });
        },
        selectStakeholder() {
            this.$emit('selectStakeholder', this.searchStakeholder);
        },
        createStakeholder() {
            if (!this.searchText.length) {
                return this.$emit('createStakeholder', {});
            }
            let params = {

            };

            if (this.searchText.includes('@')) {
                params.email = this.searchText;
            }
            else if (this.searchText.match(/^([0-9]|[\-+#() ])*$/g) != null) {
                params.phone = this.searchText;
            }
            else {
                let names = this.searchText.split(' ');
                names.forEach((name, i) => {
                    if (i == 0) {
                        params.first_name = name;
                    }
                    else if (i == 1) {
                        params.last_name = name;
                    }
                    else {
                        params.last_name += ' ' + name;
                    }
                });
            }
            return this.$emit('createStakeholder', params);
        },
        getAddressData(place) {
            let addressObj = this.parseGoogleMapsData(place);
            if (addressObj) {
                this.address = addressObj;
                this.searchStakeholders();
            }
        },
        getStakeholders(searchText) {
            this.searchText = searchText;
            if (this.requestCancel) {
                this.requestCancel.cancel();
            }
            if (searchText.length < this.minCharSearch ) {
                this.stakeholders = [];
                return;
            }
            this.searchesRunning ++;
            this.requestCancel = this.$http.CancelToken.source();
            const params = {
                email: searchText,
                name: searchText,
                phone: searchText,
                cancelToken: this.requestCancel.token
            };
            this.$store.dispatch('getStakeholdersBySearchParams', params).then((response) => {
                if (!response) {
                    return;
                }
                this.stakeholders = response.map((stakeholder) => {
                    stakeholder.display_name = stakeholder.email;
                    return stakeholder;
                });
            }).finally(() => {
                this.searchesRunning --;
            });
        },
        limitText (count) {
            return `and ${count} other stakeholders`
        },
        newStakeholderTag( query ) {
            const newStakeholder = {
                id: -1,
                email: query,
                display_name: query
            };
            this.tempStakeholder = newStakeholder;
            this.searchStakeholder = newStakeholder;
        },
        highlightMatch(text) {
            if (!this.searchText.length) {
                return text;
            }
            return text.replace(new RegExp(this.searchText, 'gi'), function(a) {
                return '<strong>' + a + '</strong>';
            });
        },
        onFirstHover() {
            this.userCanHover = false;
            window.removeEventListener('mouseover', this.onFirstHover, false);
        }
    },
    mounted() {
        document.getElementById('stakeholders').setAttribute('autocomplete', 'off');

        window.addEventListener('keydown', this.keydownHandler);
        window.addEventListener('keyup', this.keyupHandler);
        window.addEventListener('touchstart', this.onFirstHover, false);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.keydownHandler);
        window.removeEventListener('keyup', this.keyupHandler);
        if (this.userCanHover) {
            window.removeEventListener('touchstart', this.onFirstHover, false);
        }
        if (this.requestCancel) {
            this.requestCancel.cancel();
        }
    }
}
</script>

<style lang="scss">
.multiselect__placeholder {
    display: inline-block !important;
    padding-left: 4px !important;
    font-size: 16px !important;
    color: #636c72 !important;
    margin-bottom: 0 !important;
    padding-top: 0px !important;
}
.multiselect__tags {
    border: 1px solid #ced4da !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}
.text-divider {
    padding: 0 5px 0 5px;
    opacity: 0.15;
}
.text-missing {
    opacity: 0.3;
}
</style>
