<template>
    <div class="phone">
        <spinner v-if="processing"></spinner>
        <page-heading>
            Phone call: What's the phone number?
        </page-heading>

        <b-row align-h="center">
            <b-col sm="12" md="10" lg="10" xl="10">
                <b-form-input v-on:keyup.enter="setPhone" v-model="phone" inputmode="numeric" size="lg" placeholder="e.g. 0423490622"></b-form-input>
            </b-col>
        </b-row>

        <!--<b-row align-h="center" class="mt-4">
            <b-col sm="12" md="10" lg="10" xl="10">
                <b-button block size="lg" variant="primary" @click="setPhone()">
                    <span>Confirm phone number</span>
                </b-button>
            </b-col>
        </b-row>-->

        <footer-button
        title="Confirm phone number"
        :onClick="setPhone"
        >
        </footer-button>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
export default {
    components: {
        'spinner': Spinner
    },
    data() {
        return {
            phone: null
        }
    },
    methods: {
        setPhone() {
            this.processing = true;
            this.$store.commit('setPhone', this.phone);
            this.$store.dispatch('getStakeholdersByPhone').then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                this.$router.push('phonecall/outcome');
            }).finally(() => {
                this.processing = false;
            });
        }
    },
    created() {
        this.$store.commit('resetStore');
        this.$store.commit('setEngagementMethod', 'Phone - outgoing');
    }
}

</script>
