<script>
import EventSelector from '@/components/EventSelector'
export default {
    extends: EventSelector,
    computed: {
        eventsList() {
            return this.possibleEvents.filter((event) => {
                return event.type != 'Doorknock';
            });
        }
    },
    methods: {
        selectEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            if (event.form_id) {
                this.$store.dispatch('getForm', event.form_id);
            }
            this.$router.push('/eventcount/counter');
        },
        editEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            this.$router.push({ 
                name: 'event-edit', 
                params: { 
                    nextUrl: {
                        path: '/eventcount',
                    }
                }
            });
        },
        createEvent() {
            this.$router.push({ 
                name: 'event-create', 
                params: { 
                    nextUrl: {
                        path: '/eventcount/counter',
                    }
                }
            });
        }
    },
    created() {
        this.$store.commit('resetStore');

        this.processing = true;
        this.$store.commit('setAreCountingEventAttendance', true);

        if (this.useSydMetroWest) {
            this.$store.commit('setEngagementMethod', this.COMMUNITY_INFORMATION_SESSION_METHOD.method);
            this.eventName = this.COMMUNITY_INFORMATION_SESSION_METHOD.text;
            this.nameUsesAn = false;
            this.$store.dispatch('getTodaysEvents', this.COMMUNITY_INFORMATION_SESSION_METHOD.method).finally(() => {
                this.processing = false;
            })
        }
        else {
            this.$store.commit('setEngagementMethod', this.EVENT_FEEDBACK_METHOD.method);
            this.$store.dispatch('getTodaysEvents').finally(() => {
                this.processing = false;
            })
        }
    }
}
</script>