<template>
    
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading :displayBack="true">
            <template v-slot:default>
                Submit form: {{eventForm.name}}
            </template>
        </page-heading>

        <formio :form="eventForm.config" :submission="initialData" options="" v-on:submit.prevent="" v-on:change="formChange"></formio>

        <footer-button
        :disabled="!submissionValid"
        title="Submit"
        :onClick="submit"
        >
        </footer-button>
    </b-container>
</template>

<script>
import { Form as Formio } from 'vue-formio';
import Spinner from '@/components/Spinner.vue';

export default {
    components: { 
        'spinner': Spinner,
        'formio': Formio 
    },
    data() {
        return {
            submissionData: {},
            submissionValid: false,
            initialData: {}
        }
    },
    computed: {
        submissionSummary() {
            let summary = '';
            let componentCount = 0;
            Object.keys(this.submissionData).forEach((key) => {
                if (key !== 'submit') {

                    if (componentCount > 0) {
                        summary += '---\n';
                    }
                    summary += this.snakeCaseToTitleCase(key);
                    summary += ': ';
                    if (! this.submissionData[key] ) {
                        summary += '';
                    } else if (typeof this.submissionData[key] === 'string') {
                        summary += this.submissionData[key];
                    } else if (typeof this.submissionData[key] === 'object') {
                        let keyCount = 0;
                        Object.keys(this.submissionData[key]).forEach((vKey) => {
                            if (this.submissionData[key][vKey]) {
                                if (keyCount > 0) {
                                    summary += ', ';
                                }

                                summary += vKey;
                                keyCount ++;
                            }
                        })
                    }
                    summary += '\n';

                    componentCount ++;
                }
            });

            return summary;
        }
    },
    methods: {
        formChange(data) {
            this.submissionData = data.data;
            this.submissionValid = data.isValid;
        },
        submit() {
            this.$store.dispatch('submitForm', {
                data: {
                    ...this.submissionData,
                    submission_summary: this.submissionSummary
                }
            }).then(() => {
                this.$router.go(-1);
            })
        },
        snakeCaseToTitleCase(str) {
            let newString = str.replace(
                /_\S*/g,
                function(txt) {
                    txt  = txt.substr(1);
                    return ' ' + txt;
                }
            );

            return newString.charAt(0).toUpperCase() + newString.substr(1).toLowerCase()
        }
    },
    mounted() {
        let keys = Object.keys(this.stakeholder).filter((key) => {
            return this.eventForm.config.components.some((component) => {
                return component.key == key;
            })
        });

        let stakeholderData = keys.reduce((prev, current) => {
            prev[current] = this.stakeholder[current];
            return prev;
        }, {});

        this.initialData = {
            data: stakeholderData,
        };
    }
}
</script>

<style lang="scss">
    .formio-component-form {
        @import "../../node_modules/formiojs/dist/formio.form.min.css";

        .formio-component-submit {
            display: none;
        }
    }
</style>
