<template>
    <div class="extra-information">
        <spinner v-if="processing"></spinner>
        <page-heading :displayBack="true">
            <template v-slot:default>
                {{pageHeading}}
            </template>
            <template v-slot:subHeading>
                Continue to edit this stakeholder below or <p><a @click.prevent="finish()" class="text-success">{{finishText.toLowerCase()}}</a></p>
            </template>
        </page-heading>

        <b-row v-if="processing">
            <b-col>
                <h2>Loading...</h2>
            </b-col>
        </b-row>

        <card-list v-if="!processing" :items="options" keyName="text" 
            :selectItem="(option) => {option.method()}" 
            :disableItem="disableOption">
            <template v-slot="option">
                <h4>{{ option.text }}</h4>
            </template>
        </card-list>



        <b-row align-h="center" class="mt-1">
            <b-col sm="12" md="10" class="border-top border-dark pt-3">
                <h4>Additional group members: <strong>{{attendance}}</strong></h4>
            </b-col>
            <b-col sm="12" md="5">
                <card-button :onClick="incrementAttendees" variant="primary">
                    Add +1 attendee
                </card-button>
            </b-col>
            <b-col sm="12" md="5">
                <card-button :onClick="decrementAttendees" variant="danger">
                    Remove -1 attendee
                </card-button>
            </b-col>
            <b-col sm="12" md="10" class="border-bottom border-dark pb-3"></b-col>
        </b-row>

        <engagement-summary v-if="!processing"
        title="Engagement summary"
        stakeholderEditUrl="/stakeholder/edit"
        engagementEditUrl="/engagement/edit"
        :value="true"
        :stakeholderCount="attendance">
        </engagement-summary>

        <!--<b-row v-if="!processing" align-h="center">
            <b-col sm="12" md="10" lg="10" xl="10">
                <b-button block size="lg" @click="finish" variant="primary">Finish</b-button>
            </b-col>
        </b-row>-->

        <footer-button v-if="!processing"
        :title="finishText"
        :onClick="finish"
        >
        </footer-button>

    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import { Promise } from 'q';
import CardList from '@/components/CardList.vue';
import EngagementSummary from '@/components/EngagementSummary.vue';
import _ from 'lodash'
export default {
    // name: 'ExtraInformation',
    components: {
        'card-list': CardList,
        'spinner': Spinner,
        'engagement-summary': EngagementSummary
    },
    data() {
        return {
            attendance: 0,
        }
    },
    computed: {
        pageHeading() {
            if (this.isEvent) {
                return "Sign-in complete";
            }
            if (this.areDoorknocking) {
                return "Doorknock complete";
            }
            if (this.areCalling) {
                return "Phonecall complete";
            }
            return "Engagement Information";
        },
        finishText() {
            if (this.isEvent) {
                if (this.$store.state.areCountingEventAttendance) {
                    return "Continue counting attendance";
                }
                return "Sign-in another stakeholder";
            }
            if (this.areDoorknocking) {
                return "Doorknock another stakeholder";
            }
            if (this.areCalling) {
                return "Call another stakeholder";
            }
            return "Finish";
        },
        options() {
            return [
                {
                    text: 'View stakeholder on map',
                    method: ()=>{this.$router.push('/stakeholder/map')}
                },
                {
                    text: 'Start web portal session',
                    method: this.startPortalSession
                },
                ...(this.eventForm.id ? [{
                    text: 'Submit form: '+ this.eventForm.name,
                    method: ()=>{this.$router.push({
                        name: 'form-submit',
                    })}
                }] : []),
                {
                    text: 'Edit stakeholder information',
                    method: ()=>{this.$router.push({
                        name: 'stakeholder-edit',
                    })}
                },
                {
                    text: 'Add notes about this engagement',
                    method: ()=>{this.$router.push({
                        name: 'engagement-edit',
                    })}
                }, 
                {
                    text: 'Add notes about this event',
                    method: ()=>{this.$router.push({
                        name: 'event-notes'
                    })}
                }
            ];
        }
    },
    methods: {
        finish() {
            this.$router.push("/");

            if (this.communityEvent.id) {
                const event = _.clone(this.communityEvent);
                const method = this.engagement.method;
                const form = this.eventForm;
                this.$store.commit('resetStore');
                this.$store.commit('setEvent', event)
                this.$store.commit('setEngagementMethod', method)
                this.$store.commit('setForm', form);
            }
            else {
                this.$store.commit('resetStore');
            }

            if (this.areDoorknocking) {
                this.$router.push('/doorknock/counter');
            }
            else if (this.isEvent) {
                if (this.$store.state.areCountingEventAttendance) {
                    this.$router.push('/eventcount/counter');
                }
                else {
                    this.$router.push('/eventfeedback/search');
                }
            }
            else if (this.areCalling) {
                this.$router.push(this.PHONE_METHOD.route);
            }
        },
        startPortalSession() {
            this.processing = true;
            let windowReference = window.open();

            this.$store.dispatch('getPortalUrl').then((response) => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                windowReference.location = response;
            }).finally(() => {
                this.processing = false;
            })
        },
        disableOption(option) {
            if ((this.areCalling || !this.$store.state.project.portal_enabled) && 
                option.text == 'Start web portal session') {
                    
                return true;
            }
            if (option.text == 'View stakeholder on map' && !this.stakeholderAddress.length) {
                return true;
            }
            if (option.text == 'Add notes about this event' && !this.isEvent) {
                return true;
            }
            return false;
        },
        incrementAttendees() {
            this.attendance ++;
            this.$toasted.show('+1', {
                position: 'top-right',
                duration: 3000,
                keepOnHover: true
            });
            this.$store.dispatch('incrementManualCount', 1)
            .then(() => {
                this.$store.commit('setStakeholderCount', this.attendance);
            }).catch((error) => {
                this.attendance -= 1;
                throw error;
            });
        },
        decrementAttendees() {
            if (this.attendance == 0) {
                return;
            }
            this.$toasted.show('-1', {
                position: 'top-right',
                duration: 3000,
                keepOnHover: true
            });
            this.attendance --;
            this.$store.dispatch('decrementManualCount', 1)
            .then(() => {
                this.$store.commit('setStakeholderCount', this.attendance);
            }).catch((error) => {
                this.attendance += 1;
                throw error;
            });
        }
    },
    created() {
        this.attendance = this.$store.state.stakeholderCount;
    }
}

</script>
