<template>
<div>
    <b-container>
        <div class="wrapper-page">
            <div class="text-center">
                <img srcset="../assets/ca-logo-2021-640x256.png 2x" src="../assets/ca-logo-2021-320x128.png" width="320" height="128" alt="Community Analytics" class="img-responsive" style="margin: 0 auto">
            </div>

            <div>
                <spinner v-if="processing"></spinner>

                <b-row v-if="processing">
                    <b-col>
                        <h2>Loading...</h2>
                    </b-col>
                </b-row>
                
                <div v-else-if="projects.length">
                    <b-row>
                        <b-col>
                            <h2>Please select a project from the list below</h2>
                        </b-col>
                    </b-row>

                    <card-list :items="projects" keyName="id" :selectItem="selectProject">
                        <template v-slot="project">
                            <h4>{{ getProjectName(project) }}</h4>
                        </template>
                    </card-list>

                    <pagination class="mt-3" :pagination="projectsPagination" @pageChange="pageChange"></pagination>
                </div>

                <div v-else>
                    <b-row>
                        <b-col>
                            <h2>There are no projects available</h2>
                        </b-col>
                    </b-row>

                    <b-row align-h="center">
                        <b-col sm="12" md="12" lg="10" xl="10">
                            <card-button
                            :title="backTitle"
                            :onClick="goBack"
                            variant="primary"
                            >
                            </card-button>
                        </b-col>
                    </b-row>
                </div>
        </div>
        </div>
    </b-container>
</div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import CardList from '@/components/CardList.vue';
import Pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';
export default {
    components: {
        'card-list': CardList,
        'spinner': Spinner,
        'pagination': Pagination
    },
    data() {
        return {
            page: 1,
            projectsUrl: this.$config.baseAPIUrl + this.$config.getProjectsUrl,
        }
    },
    computed: {
        ...mapState([
            'client', 
            'project',
            'projects',
            'projectsPagination'
        ]),
        backTitle() {
            if (!this.$store.state.provider) {
                return 'Log out';
            }

            if (!this.$store.state.providerUrl) {
                return 'Log out';
            }

            if (this.$store.state.provider === "cms") {
                return 'Go back to Community Analytics';
            }

            return 'Log out';
        },
    },
    methods: {
        selectProject(project) {
            let user = JSON.parse(localStorage.getItem('user'));
            user.data.project = project;
            localStorage.setItem('user', JSON.stringify(user));
            this.$store.commit('setProject', project);
            this.$store.dispatch('getDistributionLists');
            this.$router.push('/');
        },
        signout() {
            localStorage.removeItem('user');
            localStorage.removeItem('client');
            this.$router.push('/login');
        },
        goBack() {
            if (!this.$store.state.provider) {
                return this.signout();
            }

            if (!this.$store.state.providerUrl) {
                return this.signout();
            }

            if (this.$store.state.provider === "cms") {
                return window.location = this.$store.state.providerUrl;
            }

            return this.signout();
        },
        getProjects(page) {
            this.processing = true;
            this.$store.dispatch('getProjects', page).then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                if (this.projects.length === 1) {
                    this.selectProject(this.projects[0]);
                }
            }).finally(() => {
                this.page = page;
                this.processing = false;
            });
        },
        pageChange(page) {
            if (page === this.page) {
                return;
            }
            this.getProjects(page);
        },
        getProjectName(project) {
            if (this.client === null && project.client) {
                return project.client.short_name + ' > ' + project.name;
            }

            return project.name;
        }
    },
    created() {
        if (this.client) {
            document.title = "CA Canvass | " + this.$store.state.client.name;
        }
        else {
            document.title = "CA Canvass";
        }
        this.getProjects();
    }
}
</script>

<style lang="scss" scoped>
.wrapper-page {
    padding-top: 7.5%;
    margin: 0 auto;
}

.img-responsive {
    display: block;
    max-width: 360px;
    height: auto;
}

.dark {
    background: #181f2f;
    color: #FFF;
    min-height: 100%;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442
}
</style>
