<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>{{title}}</page-heading>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="name">Name <span class="badge-req badge badge-danger">Required</span></label>
                    <b-form-input
                        id="name"
                        v-model="localEvent.name"
                        placeholder="Enter event name here"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group>
                    <label for="start-date">Start date <span class="badge-req badge badge-danger">Required</span></label>
                    <datetime 
                        v-model="startDate"
                        input-class="form-control"
                        type="datetime"
                        :max-datetime="latestStartDate"
                        :use12-hour="true"
                        :minute-step="15"
                        :format="datetimeFormat"
                        value-zone="local"
                        required
                    ></datetime>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group>
                    <label for="end-date">End date <span class="badge-req badge badge-danger">Required</span></label>
                    <datetime 
                        v-model="endDate"
                        input-class="form-control"
                        type="datetime"
                        :min-datetime="earliestEndDate"
                        :use12-hour="true"
                        :minute-step="15"
                        :format="datetimeFormat"
                        value-zone="local"
                        required
                    ></datetime>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="type">Type <span class="badge-req badge badge-danger">Required</span></label>
                    <multiselect class="my-2" id="type" 
                        v-model="localEvent.type"
                        :options="types"
                        :searchable="false"
                        :close-on-select="true"
                        :disabled="typesDisabled"
                        placeholder="Select an event type"
                    >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="attendees">Number of attendees</label>
                    <b-form-input
                        id="attendees"
                        v-model="localEvent.manual_count"
                        placeholder="Enter number of attendees here"
                        type="number"
                        min="0"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="text-left pt-4 pb-5">
            <b-row align-h="center" align-v="end">
                <b-col sm="9" md="8" lg="8" xl="8" class="border-bottom">
                    <h6 @click.prevent="toggleLocation" class="summary-title float-left">Location: {{addressToString(address)}}</h6>
                </b-col>
                <b-col sm="3" md="2" lg="2" xl="2" class="border-bottom">
                    <button v-if="!showLocation" @click.prevent="toggleLocation" class="edit-button float-right bg-primary">
                        Edit
                    </button>
                    <button v-if="showLocation && useHideButtons" @click.prevent="toggleLocation" class="edit-button float-right bg-primary">
                        Hide
                    </button>
                </b-col>
            </b-row>
            <b-collapse v-model="showLocation" id="location-collapse" class="mt-2">
                <location-editor v-if="showLocation" v-model="address" :showAddressFields="showLocationFields" :location="localEvent.location" @locationChanged="locationChanged" @input="updateAddress">
                </location-editor>
            </b-collapse>
        </div>

        <div class="text-left pt-4 pb-5">
            <b-row align-h="center" align-v="end">
                <b-col sm="6" md="5" lg="5" xl="5" class="border-bottom">
                    <h6 @click.prevent="toggleNotes" class="summary-title float-left">Notes:</h6>
                </b-col>
                <b-col sm="6" md="5" lg="5" xl="5" class="border-bottom">
                    <button v-if="!showNotes" @click.prevent="toggleNotes" class="edit-button float-right bg-primary">
                        Edit
                    </button>
                    <button v-if="showNotes && useHideButtons" @click.prevent="toggleNotes" class="edit-button float-right bg-primary">
                        Hide
                    </button>
                </b-col>
            </b-row>
            <b-collapse v-model="showNotes" id="notes-collapse" class="mt-2">
                <b-row align-h="center" class="text-left">
                    <b-col sm="10">
                        <b-form-group>
                            <label for="summary">Summary</label>
                            <b-form-textarea
                                id="summary"
                                v-model="localEvent.summary"
                                placeholder="Enter event summary here"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row align-h="center" class="text-left">
                    <b-col sm="10">
                        <b-form-group>
                            <label for="stakeholder-comments">Stakeholder comments</label>
                            <b-form-textarea
                                id="stakeholder-comments"
                                v-model="localEvent.notes"
                                placeholder="Enter stakeholder comments here"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row align-h="center" class="text-left">
                    <b-col sm="10">
                        <b-form-group>
                            <label for="team-response">Team response</label>
                            <b-form-textarea
                                id="team-response"
                                v-model="localEvent.team_response"
                                placeholder="Enter team response here"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-collapse>
        </div>

        <footer-button
            title="Save and continue"
            :disabled="!validated"
            :onClick="saveEvent"
        >
        </footer-button>
    </b-container>
</template>

<script>
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import Multiselect from 'vue-multiselect';
import Spinner from '@/components/Spinner.vue';
import LocationEditor from '@/components/LocationEditor.vue';
export default {
    components: {
        'datetime': Datetime,
        'location-editor': LocationEditor,
        'spinner': Spinner,
        'multiselect': Multiselect
    },
    props: {
        nextUrl: {
            type: Object,
            default: (() => {
                return {};
            })
        },
    },
    data() {
        return {
            localEvent: {
                manual_count: 0
            },
            startDate: "",
            endDate: "",
            title: "Create new event",
            showNotes: false,
            showLocation: false,
            showLocationFields: false,
            address: {},
            useHideButtons: true,
            nextUrlObj: this.nextUrl,
            typesDisabled: false
        }
    },
    computed: {
        datetimeFormat() {
            return 'dd LLL yyyy, hh:mm a'
        },
        types() {
            if (this.engagement.method == this.DOORKNOCK_METHOD.method) {
                return ['Doorknock'];
            }
            return this.getEventTypes(this.useSydMetroWest);
        },
        validated() {
            if (!this.localEvent.name) {
                return false;
            }
            if (!this.startDate) {
                return false;
            }
            if (!this.endDate) {
                return false;
            }
            if (!this.localEvent.type) {
                return false;
            }
            return true;
        },
        latestStartDate() {
            let todayDateTime = DateTime.local().endOf('day');
            if (!this.endDate) {
                return todayDateTime.toString();
            }
            let endDateTime = DateTime.fromISO(this.endDate).toLocal();
            return todayDateTime < endDateTime ?  todayDateTime.toString() : this.endDate;
        },
        earliestEndDate() {
            let todayDateTime = DateTime.local().startOf('day');
            if (!this.startDate) {
                return todayDateTime.toString();
            }
            let startDateTime = DateTime.fromISO(this.startDate).toLocal();
            return todayDateTime > startDateTime ?  todayDateTime.toString() : this.startDate;
        },
    },
    methods: {
        saveEvent() {
            //abstract function to be overwritten
        },
        updateAddress(address) {
            this.localEvent = Object.assign(this.localEvent, address);
        },
        toggleLocation() {
            this.showLocation = !this.showLocation;
        },
        toggleNotes() {
            this.showNotes = !this.showNotes;
        },
        addressFromEvent(event) {
            return {
                address_unit_no: event.address_unit_no,
                address_street_no: event.address_street_no,
                address_street_name: event.address_street_name,
                address_suburb: event.address_suburb,
                address_state: event.address_state,
                address_country: event.address_country,
                address_postcode: event.address_postcode,
            }
        },
        locationChanged(location) {
            this.localEvent.location = location;
        }
    },
    watch: {
        startDate(val) {
            this.localEvent.start = val;
        },
        endDate(val) {
            this.localEvent.end = val;
        }
    }
}

</script>
