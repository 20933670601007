import Vue from 'vue'
import config from './config';


import './plugins/fontawesome'
import './plugins/axios'

import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './main.scss'

Vue.use(BootstrapVue)

import Toasted from 'vue-toasted';

Vue.use(Toasted)

import {CanvasMixin} from './mixins/CanvasMixin.js';
Vue.mixin(CanvasMixin);


import App from './App.vue'
import router from './router'
import store from './store'

Vue.prototype.$config = config();

Vue.config.productionTip = false

import * as VueGoogleMaps from 'vue2-google-maps';
 
Vue.use(VueGoogleMaps, {
  load: {
    key: config().mapsKey,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

import PageHeading from '@/components/PageHeading';
import SubHeading from '@/components/SubHeading';

Vue.component('PageHeading', PageHeading);
Vue.component('SubHeading', SubHeading);

import FooterButton from '@/components/FooterButton';
Vue.component('FooterButton', FooterButton);

import CardButton from '@/components/CardButton';
Vue.component('CardButton', CardButton);

import axios from 'axios';
Vue.prototype.$http = axios;

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

import 'vue-datetime/dist/vue-datetime.css'

Vue.use(VueMoment, {
  moment,
});

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (config().sentryEnabled && config().sentryDsn) {
  Sentry.init({
      dsn: config().sentryDsn,
      release: JSON.parse(unescape(process.env.VUE_APP_PACKAGE_JSON || '%7Bversion%3A0%7D')).version,
      environment: config().environment,
      integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
  });

  Sentry.configureScope(scope => {
    //code from helpful github issue: https://github.com/getsentry/sentry-javascript/issues/1466#issuecomment-412051813
    let storeLength = JSON.stringify(store.state).length;
    //padding of abritrary length for the rest of the message
    let dataPadding = 10000;
    /**
     * Don't submit data > 200kb to Sentry since the
     * entire error will be rejected.
     */
    if (storeLength + dataPadding < 200000) {
      scope.setExtra('store', store.state);
    }
    else {
      scope.setExtra('store', "store too large to record");
    }
  });
}

Vue.prototype.$sentry = Sentry;

window.Echo = null;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

