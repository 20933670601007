<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>
            Please enter your information
        </page-heading>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="stakeholder-email">
                        Email: <span class="badge-req badge badge-danger">Required</span>
                    </label>
                    <multiselect v-model="searchStakeholder" id="stakeholder-email" 
                    label="display_name" track-by="id" 
                    placeholder="Search emails" 
                    tag-placeholder="Create new stakeholder with this email"
                    open-direction="bottom" 
                    :options="searchedStakeholders" 
                    :multiple="false" 
                    :taggable="taggable"
                    :searchable="true" 
                    :loading="searchProcessing" 
                    :internal-search="false" 
                    :clear-on-select="false" 
                    :close-on-select="true" 
                    :options-limit="300" 
                    :limit="5"
                    :limit-text="limitText"
                    :max-height="600" 
                    :show-no-results="false" 
                    :hide-selected="true" 
                    :preserve-search="true"
                    :resetAfter="resetSearch"
                    @search-change="getStakeholdersByEmail"
                    @tag="createNewStakeholder">
                        <span slot="noResult">Oops! No stakeholders found. Consider changing the search query.</span>
                    </multiselect>

                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group
                    label="Subscriptions:"
                    label-for="stakeholder-subscriptions"
                >
                    <multiselect id="stakeholder-subscriptions"
                        :multiple="true" 
                        :options="distributionListOptions"
                        label="text"
                        track-by="value"
                        v-model="subscribeList"
                        :close-on-select="false"
                        placeholder="Subscribe to newsletter " >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group
                    label="Number of additional group members:"
                    label-for="attendance"
                >
                    <b-form-input
                    id="attendance"
                    v-model="attendance"
                    type="number"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Home phone:"
                    label-for="stakeholder-home-phone"
                >
                    <b-form-input
                    id="stakeholder-home-phone"
                    v-model="localStakeholder.home_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Mobile phone:"
                    label-for="stakeholder-mobile-phone"
                >
                    <b-form-input
                    id="stakeholder-mobile-phone"
                    v-model="localStakeholder.mobile_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="First name:"
                    label-for="stakeholder-first-name"
                >
                    <b-form-input
                    id="stakeholder-first-name"
                    v-model="localStakeholder.first_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Last name:"
                    label-for="stakeholder-last-name"
                >
                    <b-form-input
                    id="stakeholder-last-name"
                    v-model="localStakeholder.last_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="2">
                <b-form-group
                    label="Unit #:"
                    label-for="stakeholder-unit-no"
                >
                    <b-form-input
                    id="stakeholder-unit-no"
                    v-model="localStakeholder.address_unit_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group
                    label="Street #:"
                    label-for="stakeholder-street-no"
                >
                    <b-form-input
                    id="stakeholder-street-no"
                    v-model="localStakeholder.address_street_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Street name:"
                    label-for="stakeholder-street-name"
                >
                    <b-form-input
                    id="stakeholder-street-name"
                    v-model="localStakeholder.address_street_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="localStakeholder.email" align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Suburb:"
                    label-for="stakeholder-suburb"
                >
                    <b-form-input
                    id="stakeholder-suburb"
                    v-model="localStakeholder.address_suburb"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Postcode:"
                    label-for="stakeholder-postcode"
                >
                    <b-form-input
                    id="stakeholder-postcode"
                    v-model="localStakeholder.address_postcode"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="State:"
                    label-for="stakeholder-state"
                >
                    <b-form-input
                    id="stakeholder-state"
                    v-model="localStakeholder.address_state"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Country:"
                    label-for="stakeholder-country"
                >
                    <b-form-input
                    id="stakeholder-country"
                    v-model="localStakeholder.address_country"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" >
            <b-col xs="12" md="10" lg="10" xl="10">
                <card-button
                title="Cancel/Clear form"
                variant="cancel"
                :onClick="resetStakeholder">
                </card-button>
            </b-col>
        </b-row>

        <footer-button
        title="Save"
        :onClick="saveStakeholder"
        :disabled="!localStakeholder.email"
        >
        </footer-button>
    </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        'spinner': Spinner,
        'multiselect': Multiselect
    },
    data() {
        return {
            localStakeholder: {},
            searchStakeholder: null,
            searchesRunning: 0,
            cancel: null,
            searchedStakeholders: [],
            subscribeList: [],
            resetSearch: false,
            attendance: 0
        }
    },
    computed: {
        ...mapState([
            'distributionLists'
        ]),
        ...mapGetters([
            'distributionListOptions'
        ]),
        searchProcessing() {
            return this.searchesRunning > 0;
        },
        subscriptionOptions() {
            if (this.useSydMetroWest) {
                return this.getSydMetroSubscriptionOptions();
            }
            else if (this.useRMS) {
                return this.getRMSSubscriptionOptions();
            }
            return [];
        },
        taggable() {
            return this.searchedStakeholders.length == 0 && !this.searchProcessing;
        }
    },
    methods: {
        saveStakeholder() {
            this.$store.commit('setStakeholder', this.localStakeholder);
            this.processing = true;
            this.$store.dispatch('saveStakeholder')
            .then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                return this.$store.dispatch('saveEngagement')
            }).then(() => {
                if (this.attendance > 0) {
                    return this.$store.dispatch('incrementManualCount', this.attendance)
                }
                return Promise.resolve(null);
            }).then(() => {
                this.$swal({
                    title: 'Success!', 
                    text: 'You have successfully signed in', 
                    type: 'success',
                    timer: 3000
                });
                this.resetStakeholder();
            }).finally(() => {
                this.processing = false;
                this.$store.commit('resetStakeholder');
                this.$store.commit('resetEngagement', this.engagement);
            });
        },
        async getStakeholdersByEmail( query ) {
            const request = {
                email: query,
                event_id: this.communityEvent.id
            };
            
            if (this.cancel) {
                this.cancel.cancel();
            }
            this.searchesRunning ++;
            this.cancel = this.$http.CancelToken.source();
            this.$http.get(this.$config.baseAPIUrl + this.$config.getStakeholdersUrl, {
                params: request,
                cancelToken: this.cancel.token
            }).then((response) => {
                this.searchedStakeholders = response.data.data.map((result) => {
                    result = this.flattenLocationModel(result);
                    result.display_name = result.email + ' (';
                    if (result.first_name) {
                        result.display_name += result.first_name;
                        if (result.last_name) {
                            result.display_name += ' ' + result.last_name;
                        }
                    }
                    else {
                        result.display_name += 'Anonymous';
                    }
                    result.display_name += ')';
                    return result;
                })
            }).catch((error) => {
                if (!this.$http.isCancel(error)) {
                    throw error;
                }
            }).finally(() => {
                this.searchesRunning --;
            });
        },
        limitText (count) {
            return `and ${count} other stakeholders`
        },
        createNewStakeholder( query ) {
            const newStakeholder = {
                id: null,
                email: query,
                display_name: query + ' (new stakeholder)'
            };

            this.searchStakeholder = newStakeholder;
        },
        initStakeholder() {
            this.searchesRunning = 0;
            this.cancel = null;
            this.searchedStakeholders = [];
            this.localStakeholder = {
                id: null,
                email: null,
                phone: null,
                home_phone: null,
                mobile_phone: null,
                first_name: null,
                last_name: null,
                followup_requested: null,
                address_unit_no: null,
                address_street_no: null,
                address_street_name: null,
                address_suburb: null,
                address_state: null,
                address_postcode: null,
                address_country: null,
                address: null,
                lat: null,
                lng: null,
                distribution_lists: [],
                subscriptions: [],
                originated: null
            };

            this.subscribeList = [];
            
            if (this.distributionLists.length === 1) {
                this.subscribeList = this.distributionLists.map((list) => {
                    return {
                        value: list.id,
                        text: list.name
                    };
                });
            }
        },
        resetStakeholder() {
            this.resetSearch = true;
            setTimeout(() => {
                this.createNewStakeholder(null);
                this.initStakeholder();
                setTimeout(() => {
                    this.resetSearch = false;
                }, 1);
            }, 1)
        }
    },
    watch: {
        'searchStakeholder.email': function(val) {
            if (this.searchStakeholder === null) {
                return;
            }
            this.localStakeholder = Object.keys(this.localStakeholder).reduce((prev, key) => {
                if (key === 'distribution_lists') {
                    prev[key] = this.searchStakeholder[key].data;
                    this.subscribeList = this.searchStakeholder.distribution_lists.data.map((list) => {
                        return {
                            value: list.id,
                            text: list.name
                        };
                    });
                }
                else if (typeof this.searchStakeholder[key] !== 'undefined') {
                    prev[key] = this.searchStakeholder[key];
                }
                else {
                    prev[key] = null;
                }
                return prev;
            }, {})

            if (this.distributionLists.length === 1) {
                this.subscribeList = this.distributionLists.map((list) => {
                    return {
                        value: list.id,
                        text: list.name
                    };
                });
            }
        },
        subscribeList(val) {
            let lists = val.map((list) => {
                return this.distributionLists.find((dList) => {
                    return dList.id === list.value;
                });
            });
            this.localStakeholder.distribution_lists = lists;
        }
    },
    created() {
        this.initStakeholder();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "../variables.scss";
.create-new {
    background-color: $secondary;
}
.create-new:hover {
    background-color: $secondary;
}
</style>

