<template>
<div>
    <b-row align-h="center" align-v="center" class="event-counter">
        <b-col xs="12" md="10">
            <b-row align-h="between">
                <b-col xs="12" md="5" lg="4">
                    <b-button class="counter-button increment pull-left" @click="incrementAttendance()">
                        +1
                    </b-button>
                </b-col>

                <b-col xs="12" md="5" lg="4">
                    <b-button class="counter-button increment" @click="incrementAttendance(10)">
                        +10
                    </b-button>
                </b-col>
            </b-row>

            <b-row align-h="center">
                <b-col>
                    <h1 class="counter-value">{{attendance}}</h1>
                </b-col>
            </b-row>

            <b-row align-h="between">

                <b-col xs="12" md="5" lg="4">
                    <b-button class="counter-button decrement" @click="decrementAttendance()">
                        -1
                    </b-button>
                </b-col>

                <b-col xs="12" md="5" lg="4">
                    <b-button class="counter-button decrement" @click="decrementAttendance(10)">
                        -10
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
import io from 'socket.io-client';
import Echo from "laravel-echo";
import { setInterval, clearInterval } from 'timers';

export default {
    data() {
        return {
            attendance: 0,
            echo: null,
            ajaxCheck: null,
            id: null
        }
    },
    methods:{
        incrementAttendance(amount) {
            if (typeof amount === 'undefined') {
                amount = 1;
            }
            //Separate the shown attendance from API interaction for a more responsive
            //(fast) interface
            this.attendance += amount;
            this.$store.dispatch('incrementManualCount', amount).catch((error) => {
                this.attendance -= amount;
                throw error;
            });
        },
        decrementAttendance(amount) {
            if (typeof amount === 'undefined') {
                amount = 1;
            }
            //Separate the shown attendance from API interaction for a more responsive
            //(fast) interface
            if (amount > this.attendance) {
                amount = this.attendance;
            }
            if (amount > 0) {
                this.attendance -= amount;
                this.$store.dispatch('decrementManualCount', amount).catch((error) => {
                    this.attendance += amount;
                    throw error;
                });
            }
        },
        handleBroadcast(e) {
            if(e.event.uuid === this.communityEvent.uuid) {
                if(e.event.manual_count !== this.attendance) {
                    if(e.event.action && e.event.action === 'increment') {
                        // eslint-disable-next-line
                        console.log(e);
                        let increment = e.event.manual_count - this.attendance;
                        this.$toasted.show('+'+increment+' by '+e.event.user, {
                            position: 'top-right',
                            duration: 3000,
                            keepOnHover: true
                        });
                    }

                    if(e.event.action && e.event.action === 'decrement') {
                        // eslint-disable-next-line
                        console.log(e);
                        let decrement = e.event.manual_count - this.attendance;
                        this.$toasted.show(decrement+' by '+e.event.user, {
                            position: 'top-right',
                            duration: 3000,
                            keepOnHover: true
                        });
                    }
                }

                this.attendance = e.event.manual_count;
                this.$store.commit('setEvent', e.event);
            }
        },
        getCurrentCount() {
            return this.$http.get(this.$config.baseAPIUrl + this.$config.getEventsUrl + '/' + this.communityEvent.id)
            .then((response) => {
                response.data.data = this.flattenLocationModel(response.data.data);
                this.attendance = response.data.data.manual_count;
                this.$store.commit('setEvent', response.data.data);
            });
        }
    },
    mounted() {
        this.attendance = this.communityEvent.manual_count;
        this.id = this.project.id;

        window.Echo.channel(this.$config.eventCounterEchoChannel1)
        .listen(this.$config.eventCounterEchoBroadcast1, this.handleBroadcast);

        window.Echo.channel(this.$config.eventCounterEchoChannel2)
        .listen(this.$config.eventCounterEchoBroadcast2, this.handleBroadcast);

        window.Echo.private(this.$config.eventCounterEchoChannelPrivate+this.id)
        .listen(this.$config.eventCounterEchoBroadcastPrivate, this.handleBroadcast);

        this.getCurrentCount();
        this.ajaxCheck = setInterval(this.getCurrentCount, this.$config.eventPollInterval);
    },
    destroyed() {
        window.Echo.leave(this.$config.eventCounterEchoChannel1);
        window.Echo.leave(this.$config.eventCounterEchoChannel2);
        window.Echo.leave(this.$config.eventCounterEchoChannelPrivate+this.id);
        clearInterval(this.ajaxCheck);
    },
    watch: {
        attendance(newVal) {
            this.$emit('attendance', newVal);
        }
    }
}
</script>
