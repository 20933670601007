<template>
    <b-collapse v-model="visible" class="mt-2">
        <form @submit.prevent="goToSearchAddress()" class="form-horizontal" action="#">
            <b-row align-h="center" align-v="center">
                    <b-col sm="12" md="12" lg="9" xl="9">
                        <!--<b-form-input id="location-search" placeholder="Search by address" sm="4" size="lg" v-model="searchAddressInput" trim></b-form-input>-->
                        <gmap-autocomplete
                            placeholder="Search by address" @place_changed="setSearchAddress" :selectFirstOnEnter="true" class="form-control form-control-lg"
                        >
                        </gmap-autocomplete>
                    </b-col>
                    <b-col sm="12" md="12" lg="3" xl="3">
                        <card-button :onClick="geolocate" :disabled="loadingGeoLocation">My location</card-button>
                    </b-col>
            </b-row>
        </form>

        <location-map ref="map" @mapLoaded="goToInitialPosition" @locationChanged="updatePosition"
            :initialCenter="{lat: -34.0404501,lng: 147.9291009}" :useDragMarker="true" 
            :initialZoomLevel="$config.initialMapZoomLevel" :activeZoomLevel="$config.activeMapZoomLevel">
        </location-map>

        <b-row>
            <b-col class="text-center">
                <p>Move the map to adjust the address.</p>
            </b-col>
        </b-row>
    </b-collapse>
</template>

<script>
import LocationMap from '@/components/Map.vue';
export default {
    components: {
        'location-map': LocationMap
    },
    props: {
        value: {
            type: Object,
            default: () => {return{}}
        },
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            address: {},
            formattedAddress: {},
            autocomplete: null,
            addressSet: false,
            searchAddress: null,
            loadingGeoLocation: true,
        }
    },
    computed: {
        displayAddress: function() {
            if (this.formattedAddress) {
                return this.formattedAddress.formatted_address;
            }

            return null;
        }
    },
    methods: {
        updatePosition(position) {
            this.geocodeAddress(position).then(() => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
            }).catch(function(e) {
                console.log(e);
            });
        },
        geocodeAddress(position) {
            return this.$refs.map.getGeocodedAddress(position.lat, position.lng).then((address) => {
                if (!this.isAlive) {
                    return Promise.reject('Promise cancelled');
                }
                this.address = address;
                this.addressSet = true;
            }).catch((message) => {
                console.log(message)
            });
        },
        goToInitialPosition() {
            if (Object.keys(this.value).reduce((check, key) => {
                if (check) {
                    return true;
                }
                return this.value[key];
            }, false)) {
                this.$refs.map.searchLocation(this.addressToString(this.value));
            }
            else {
                this.geolocate();
            }
        },
        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        geolocate : function() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    if (this.$store.state.position) {
                        this.loadingGeoLocation = false;
                        this.$refs.map.setLocation({
                            lng: this.$store.state.position.coords.longitude,
                            lat: this.$store.state.position.coords.latitude
                        });
                        resolve();
                    }
                    else {
                        navigator.geolocation.getCurrentPosition((position) => {
                            if (!this.isAlive) {
                                return reject('Promise cancelled');
                            }

                            this.loadingGeoLocation = false;
                            this.$refs.map.setLocation({
                                lng: position.coords.longitude,
                                lat: position.coords.latitude
                            });
                            resolve();

                        }, (error) => {
                            if (!this.isAlive) {
                                return reject('Promise cancelled');
                            }

                            if (this.$store.state.previousStakeholder && this.addressToString(this.$store.state.previousStakeholder)) {
                                let prevStakeholder = this.$store.state.previousStakeholder;
                                if (prevStakeholder.lat && prevStakeholder.lng) {
                                    this.$refs.map.setLocation({
                                        lat: parseFloat(prevStakeholder.lat), 
                                        lng: parseFloat(prevStakeholder.lng)
                                    });
                                }
                                else {
                                    this.$refs.map.searchLocation(this.addressToString(prevStakeholder));
                                }
                            }
                            else if (this.$store.state.project.longitude && this.$store.state.project.latitude) {
                                this.$refs.map.setLocation({
                                    lat: parseFloat(this.$store.state.project.latitude),
                                    lng: parseFloat(this.$store.state.project.longitude)
                                });
                            }
                        });
                    }
                }
                else {
                    reject('Geolocator not available');
                } 
            }).catch((e) => {
                console.log(e);
            })
        },
        saveAddress() {
            this.formattedAddress = this.parseGoogleMapsData(this.address);
            if (Object.keys(this.formattedAddress).reduce((check, key) => {
                if (check) {
                    return true;
                }
                if (this.formattedAddress[key] == 'address_unit_no' || 
                    this.formattedAddress[key] == this.value[key]) {
                    
                    return false;
                }
                return true;
            }, false)) {
                this.$emit('input', this.formattedAddress);
            }
        },
        setSearchAddress(searchAddress) {
            this.searchAddress = searchAddress;
            this.goToSearchAddress();
        },
        goToSearchAddress() {
            if (this.searchAddress && this.searchAddress.geometry) {
                const position = {
                    lat: this.searchAddress.geometry.location.lat(),
                    lng: this.searchAddress.geometry.location.lng()
                }

                this.$refs.map.setLocation(position);
            }
        }
    },
    watch: {
        address: {   
            handler: function(newVal) {
                this.saveAddress()
            },
            deep: true,
        },
        value: {
            handler: function(newVal){
                this.formattedAddress = newVal;
            },
            deep: true
        }
    },
    created() {
        this.formattedAddress = this.value;
    }
}

</script>

<style lang="scss" scoped>
@import "../variables.scss";
.doorknock-location {
    .vue-map-container {
        height: $map-height;
        margin: $margin-default;
    }

    h2.address {
        margin: 1rem auto;
        width: 100%;
        opacity: 0;
        transition: opacity 2.0s ease;
        
        &.address-set {
            opacity: 1;
        }
    }
}
</style>
