<template>
    <GmapMap
        :center="center"
        map-type-id="hybrid"
        :options="{
            disableDefaultUI: false,
            streetViewControl: false
        }"
        ref="mapRef"
        :tilt="0"
        :zoom="zoomLevel"
    >
        <gmap-cluster
            @click="clusterClickEvent($event)"
        >
            <gmap-custom-marker
                :key="index"
                v-for="(m, index) in mapMarkers"
                :marker="m.position"
                @click.native="markerClickEvent(m)"
            >   
                <font-awesome-icon icon="map-marker-alt" size="4x" :style="{ color: m.color }"></font-awesome-icon>
            </gmap-custom-marker>
        </gmap-cluster>
        <gmap-info-window 
            @closeclick="showWindow=false" 
            :opened="showWindow" 
            :position="windowPosition"
            :options="{
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            }"
        >
            <slot name="marker-window" v-if="activeMarker" v-bind="activeMarker">

            </slot>
            <slot name="cluster-window" v-if="activeCluster" v-bind="activeCluster">

            </slot>
        </gmap-info-window>
    </GmapMap>
</template>

<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import {gmapApi} from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
export default {
    components: {
        'gmap-custom-marker': GmapCustomMarker,
        'gmap-cluster': GmapCluster
    },
    props: {
        markers: {
            type: Array,
            default: () => {return []}
        },
        useDragMarker: {
            type: Boolean,
            default: false
        },
        initialZoomLevel: Number,
        activeZoomLevel: Number,
        initialCenter: Object
    },
    data() {
        return {
            dragMarker: null,
            map: null,
            center: this.initialCenter,
            geocoder: null,
            zoomLevel: this.initialZoomLevel,
            activeMarker: null,
            windowPosition: null,
            showWindow: false,
            activeCluster: null,
            markerClickActive: false,
            dragging: false
        }
    },
    computed: {
        google: gmapApi,
        mapMarkers() {
            const markers = this.markers.slice();
            if (this.useDragMarker && this.map) {
                markers.push(this.dragMarker);
            }
            return markers;
        }
    },
    methods: {
        markerClickEvent(marker) {
            this.markerClickActive = true;
            if(typeof marker.click === 'function') {
                marker.click();
            }
            else {
                if (JSON.stringify(this.activeMarker) == JSON.stringify(marker)) {
                    this.showWindow = !this.showWindow;
                }
                else {
                    this.activeMarker = marker;
                    this.activeCluster = null;
                    this.windowPosition = marker.position;
                    this.showWindow = true;
                }
            }
        },
        clusterClickEvent(event) {
            this.windowPosition = event.getCenter();
            this.activeCluster = {};
            this.activeCluster.position = event.getCenter();
            this.activeCluster.markers = this.getMarkersByPositions(event.getMarkers().map(element => {
                return element.getPosition()
            }));
            this.showWindow = true;
            this.activeMarker = null;
        },
        getMarkersByPositions(positions) {
            let matches = [];
            let markerList = [...this.markers];
            for (let i in positions) {
                let position = positions[i];
                for (let j in markerList) {
                    if (this.arePositionsEqual(markerList[j].position.lat, markerList[j].position.lng,
                        position.lat(), position.lng())) {
                        matches.push(markerList[j]);
                        markerList.splice(j, 1);
                        break;
                    }
                }
            }
            return matches;
        },
        arePositionsEqual(lat1, lng1, lat2, lng2) {
            let lat1Dec = this.countDecimals(lat1);
            let lng1Dec = this.countDecimals(lng1);
            let lat2Dec = this.countDecimals(lat2);
            let lng2Dec = this.countDecimals(lng2);

            if (lat1Dec > lat2Dec) {
                lat1 = lat1.toFixed(lat2Dec);
            }
            else if (lat2Dec > lat1Dec) {
                lat2 = lat2.toFixed(lat1Dec);
            }

            if (lat1 != lat2) {
                return false;
            }

            if (lng1Dec > lng2Dec) {
                lng1 = lng1.toFixed(lng2Dec);
            }
            else if (lng2Dec > lng1Dec) {
                lng2 = lng2.toFixed(lng1Dec);
            }

            if (lng1 != lng2) {
                return false;
            }
            
            return true;
            
        },
        //From https://stackoverflow.com/a/17369384
        countDecimals (value) { 
            if ((value % 1) != 0) 
                return value.toString().split(".")[1].length;  
            return 0;
        },
        searchLocation(address) {
            return new Promise((resolve, reject) => {
                this.geocoder.geocode({
                    address: address
                }, (results, status) => {
                    if (status === 'OK') {
                        this.center = new this.google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                        this.zoomLevel = this.activeZoomLevel;

                        if (this.useDragMarker) {
                            this.dragMarker.position = this.center;
                        }
                        resolve();
                    }
                    else {
                        reject('Sorry, failed to find this location due to: ' + status);
                    }
                });
            });
        },
        getGeocodedAddress(lat, lng) {
            // get the latlng from the map center
            var latlng = {
                lat: typeof lat !== 'undefined' ? lat : this.map.getCenter().lat(),
                lng: typeof lng !== 'undefined' ? lng : this.map.getCenter().lng()
            };

            // use the geocoder to convert that latlng to an address result
            return new Promise((resolve, reject) => {
                this.geocoder.geocode({
                    location: latlng
                }, (results, status) => {
                    if (status === 'OK') {

                        if (results[0]) {
                            resolve(results[0]);

                        } else {
                            reject('Sorry, failed to determine address due to: no data found');
                        }
                    } else {
                        reject('Sorry, failed to determine address due to: ' + status);
                    }
                });
            })
        },
        getMapBounds() {
            return new Promise((resolve) => {
                let bounds = this.map.getBounds();
                if (bounds) {
                    return resolve({
                        lng_sw: bounds.getSouthWest().lng(),
                        lng_ne: bounds.getNorthEast().lng(),
                        lat_sw: bounds.getSouthWest().lat(),
                        lat_ne: bounds.getNorthEast().lat()
                    });
                }
                else {
                    this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
                        bounds = this.map.getBounds();
                        return resolve({
                            lng_sw: bounds.getSouthWest().lng(),
                            lng_ne: bounds.getNorthEast().lng(),
                            lat_sw: bounds.getSouthWest().lat(),
                            lat_ne: bounds.getNorthEast().lat()
                        });
                    });
                }
            });
        },
        setLocation(center) {
            this.center = new this.google.maps.LatLng(center.lat, center.lng);
            this.zoomLevel = this.activeZoomLevel;
            if (this.useDragMarker) {
                this.dragMarker.position = this.center;
            }
        },
        bindEventListeners: function() {

            this.google.maps.event.addListener(this.map, 'center_changed', () => {
                if (this.useDragMarker) {
                    this.dragMarker.position = this.map.getCenter();
                }
                if (!this.dragging) {
                    this.$emit('locationChanged', this.convertToSimpleCoords(this.map.getCenter()));
                }
            });

            this.google.maps.event.addListener(this.map, 'dragstart', () => {
                this.dragging = true;
            });

            this.google.maps.event.addListener(this.map, 'dragend', () => {
                this.dragging = false;
                this.$emit('locationChanged', this.convertToSimpleCoords(this.map.getCenter()));
            });

            this.google.maps.event.addListener(this.map, 'click',  (event) => {
                if (!this.markerClickActive) {
                    this.center = new this.google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
                }
                else {
                    this.markerClickActive = false;
                }

                //Prevents POI from hijacking click events
                if (event.placeId) {
                    event.stop();
                }
            })

            this.google.maps.event.addListener(this.map, 'zoom_changed', () => {
                this.$emit('zoomChanged');
            });
        },
        convertToSimpleCoords(position) {
            return {
                lng: position.lng(),
                lat: position.lat()
            };
        }

    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
            this.geocoder = new this.google.maps.Geocoder();
            window.smap = this.map;

            if (this.useDragMarker) {
                this.dragMarker = {
                    position: this.map.getCenter(),
                    color: '#1595FB',
                    click: () => {
                        this.center=this.dragMarker.position
                    }
                };
            }

            this.bindEventListeners();

            this.$emit('mapLoaded');
        });
    }
}
</script>
