import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import qs from 'qs';
import VuexPersistence from 'vuex-persist';
import { addressToString, addressToShortString, getStakeholderSubscriptions, getStakeholderSubscriptionStatus, setStakeholderSubscription, resetStakeholderSubscriptions, flattenLocationModel } from './plugins/helpers';
import config from './config';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';

const ORIGINATED = 'canvass';
const EVENT_RESULTS_TODAY = 'today';

const getDefaultState = () => {
    return {
        version: null,
        refreshRequired: false,
        client: null,
        project: null,
        user: null,
        authToken: null,
        provider: null,
        providerUrl: null,
        stakeholder: {
            id: null,
            email: null,
            phone: null,
            home_phone: null,
            mobile_phone: null,
            first_name: null,
            last_name: null,
            followup_requested: null,
            address_unit_no: null,
            address_street_no: null,
            address_street_name: null,
            address_suburb: null,
            address_state: null,
            address_postcode: null,
            address_country: null,
            address: null,
            lat: null,
            lng: null,
            subscriptions: [],
            distribution_lists: [],
            originated: null
        },
        searchStakeholder: {
            email: null,
            phone: null,
            home_phone: null,
            mobile_phone: null,
            first_name: null,
            last_name: null,
            followup_requested: null,
            address_unit_no: null,
            address_street_no: null,
            address_street_name: null,
            address_suburb: null,
            address_state: null,
            address_postcode: null,
            address_country: null,
            address: null,
        },
        stakeholderIsSet: false,
        stakeholderIsDirty: false,
        stakeholderCount: 0,
        engagement: {
            id: null,
            name: null,
            team_response: null,
            notes: null,
            sentiment: null,
            sentiment_label: null,
            requires_followup: null,
            outcome: null,
            method: null,
            event_id: null,
            category: null
        },
        engagementIsDirty: false,
        event: {
            id: null,
            name: null,
            start: null,
            end: null,
            address_unit_no: null,
            address_street_no: null,
            address_street_name: null,
            address_suburb: null,
            address_state: null,
            address_postcode: null,
            address_country: null,
            manual_count: null,
            summary: null,
            team_response: null,
            notes: null,
            form_id: null
        },
        eventIsDirty: false,
        form: {
            id: null,
            name: null,
            short_name: null,
            config: null
        },
        areCountingEventAttendance: false,
        possibleStakeholders: [],
        possibleStakeholdersPagination: null,
        previousStakeholderRequest: null,
        possibleEvents: [],
        stakeholderEmailChecked: false,
        projects: [],
        projectsPagination: null,
        previousStakeholder: null,
        nextUrl: null,
        position: null,
        sessionId: null,
        publicMode: false,
        distributionLists: [],
        distributionListsOriginal: []
    }
}

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex);

export default new Vuex.Store({
    state: getDefaultState(),
    plugins: [vuexLocal.plugin],
    getters: {
        stakeholderAddress(state) {
            return addressToString(state.stakeholder);
        },
        shortStakeholderAddress(state) {
            return addressToShortString(state.stakeholder);
        },
        distributionListOptions(state) {
            return state.distributionLists.map((list) => {
                return {
                    value: list.id,
                    text: list.name
                };
            });
        }
    },
    mutations: {
        setStakeholder(state, stakeholder) {
            if (!_.isEqual(state.stakeholder, stakeholder)) {
                state.stakeholderIsDirty = true;
            }
            state.stakeholder = _.assign(state.stakeholder,stakeholder);
        },
        setSearchStakeholder(state, stakeholder) {
            state.searchStakeholder = _.assign(state.searchStakeholder,stakeholder);
        },
        resetStakeholder(state) {
            state.stakeholderIsDirty = false;
            state.stakeholder = getDefaultState().stakeholder;
            state.stakeholderEmailChecked = getDefaultState().stakeholderEmailChecked;
            state.stakeholderIsSet = false;
            state.stakeholderCount = 0;
            state.distributionListsOriginal = [];
        },
        setStakeholderAddress(state, address) {
            state.stakeholder.address = address;
        },
        setStakeholderPosition(state, position) {
            if (state.stakeholder.lat != position.lat || state.stakeholder.lng != position.lng) {
                state.stakeholderIsDirty = true;
            }
            state.stakeholder.lat = position.lat;
            state.stakeholder.lng = position.lng;
        },
        setStakeholderSearchPosition(state, position) {
            state.searchStakeholder.lat = position.lat;
            state.searchStakeholder.lng = position.lng;
        },
        setStakeholderIsSet(state, isSet) {
            state.stakeholderIsSet = isSet;
        },
        setStakeholderId(state, id) {
            state.stakeholder.id = id;
        },
        setStakeholderCount(state, count) {
            state.stakeholderCount = count;
        },
        setEngagementStakeholderId(state, id) {
            if (state.engagement.stakeholder_id != id) {
                state.engagementIsDirty = true;
            }
            state.engagement.stakeholder_id = id;
        },
        setPhone(state, phone) {
            if (state.stakeholder.phone != phone) {
                state.stakeholderIsDirty = true;
            }
            state.stakeholder.phone = phone;
        },
        setEngagement(state, engagement) {
            if (!_.isEqual(state.engagement, engagement)) {
                state.engagementIsDirty = true;
            }
            state.engagement = _.assign(state.engagement, engagement);
        },
        setEngagementId(state, id) {
            state.engagement.id = id;
        },
        setEngagementMethod(state, method) {
            if (state.engagement.method != method) {
                state.engagementIsDirty = true;
            }
            state.engagement.method = method;
        },
        setEngagementEvent(state, eventId) {
            if (state.engagement.event_id != eventId) {
                state.engagementIsDirty = true;
            }
            state.engagement.event_id = eventId;
        },
        setEngagementName(state, name) {
            if (state.engagement.name != name) {
                state.engagementIsDirty = true;
            }
            state.engagement.name = name;
        },
        setEvent(state, event) {
            if (!_.isEqualWith(_.omit(state.event, _.functions(state.event)), _.omit(event, _.functions(event).concat['__proto__']), (val1, val2, key) => {
                if (key === 'start' || key === 'end') {
                    return +DateTime.fromISO(val1) == +DateTime.fromISO(val2);
                }
            })) {
                state.eventIsDirty = true;
            }
            state.event = _.assign(state.event, event);
        },
        setEventId(state, id) {
            state.event.id = id;
        },
        setAreCountingEventAttendance(state, areCounting) {
            state.areCountingEventAttendance = areCounting;
        },
        incrementManualCount(state, inc) {
            if (state.event.manual_count === null) {
                state.event.manual_count = inc;
            } else {
                state.event.manual_count += inc;
            }
        },
        decrementManualCount(state, inc) {
            if (state.event.manual_count === null) {
                // state.event.manual_count = inc;
            } else if (state.event.manual_count === 0) {
                // nope
            } else{
                state.event.manual_count -= inc;
            }
        },
        resetStore(state) {
            const client = state.client;
            const project = state.project;
            const projects = state.projects;
            const projectsPagination = state.projectsPagination;
            const user = state.user;
            const provider = state.provider;
            const providerUrl = state.providerUrl;
            const refreshRequired = state.refreshRequired;
            const version = state.version;
            const previousStakeholder = state.previousStakeholder;
            const sessionId = state.sessionId;
            const distributionLists = state.distributionLists;

            state = Object.assign(state, getDefaultState())
            state.client = client;
            state.project = project;
            state.projects = projects;
            state.projectsPagination = projectsPagination;
            state.user = user;
            state.provider = provider;
            state.providerUrl = providerUrl;
            state.refreshRequired = refreshRequired;
            state.version = version;
            state.previousStakeholder = previousStakeholder;
            state.stakeholderIsDirty = false;
            state.engagementIsDirty = false;
            state.eventIsDirty = false;
            state.sessionId = sessionId;
            state.distributionLists = distributionLists;
        },
        resetEngagement(state, engagement) {
            const method = engagement.method;
            const outcome = engagement.outcome;
            const event = engagement.event_id;
            state.engagement = _.assign(state.engagement, getDefaultState().engagement);
            state.engagement.method = method;
            state.engagement.event_id = event;
            state.engagement.outcome = outcome;
            state.engagementIsDirty = true;
        },
        setOutcome(state, outcome) {
            if (state.engagement.outcome != outcome) {
                state.engagementIsDirty = true;
            }
            state.engagement.outcome = outcome;
        },
        setPossibleStakeholders(state, stakeholders) {
            state.possibleStakeholders = stakeholders;
        },
        setPossibleStakeholdersPagination(state, pagination) {
            state.possibleStakeholdersPagination = pagination;
        },
        setPreviousStakeholderRequest(state, request) {
            state.previousStakeholderRequest = request;
        },
        setPossibleEvents(state, events) {
            state.possibleEvents = events;
        },
        setClient(state, client) {
            state.client = client;
        },
        setProject(state, project) {
            state.project = project;
            if (project && project.client) {
                state.client = project.client;
            }
        },
        setProjects(state, projects) {
            state.projects = projects;
        },
        setProjectsPagination(state, pagination) {
            state.projectsPagination = pagination;
        },
        setUser(state, user) {
            state.user = user
        },
        setAuthToken(state, authToken) {
            state.authToken = authToken;
        },
        setProvider(state, provider) {
            state.provider = provider;
        },
        setProviderUrl(state, url) {
            state.providerUrl = url;
        },
        setVersion(state, version) {
            state.version = version;
        },
        setRefreshRequired(state, required) {
            state.refreshRequired = required;
        },
        setStakeholderEmailChecked(state, checked) {
            state.stakeholderEmailChecked = checked;
        },
        setPreviousStakeholder(state, stakeholder) {
            state.previousStakeholder = stakeholder;
        },
        setNextUrl(state, nextUrl) {
            state.nextUrl = nextUrl;
        },
        setStakeholderIsDirty(state, isDirty) {
            state.stakeholderIsDirty = isDirty;
        },
        setEngagementIsDirty(state, isDirty) {
            state.engagementIsDirty = isDirty;
        },
        setEventIsDirty(state, isDirty) {
            state.eventIsDirty = isDirty;
        },
        setPosition(state, position) {
            state.position = position;
        },
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
        },
        setPublicMode(state, mode) {
            state.publicMode = mode;
        },
        setDistributionLists(state, lists) {
            state.distributionLists = lists;
        },
        setStakeholderDistributionLists(state, lists) {
            if (!_.isEqual(state.stakeholder.distributionLists, lists)) {
                state.stakeholderIsDirty = true;
            }
            state.stakeholder.distribution_lists = lists;
        },
        setDistributionListsOriginal(state, lists) {
            state.distributionListsOriginal = lists;
        },
        setForm(state, form) {
            state.form = form;
        }
    },
    actions: {
        saveEngagement(context) {
            if (!context.state.engagementIsDirty) {
                return Promise.resolve(context.state.engagement);
            }
            const request = Object.keys(context.state.engagement).reduce((prev, key) => {
                if (context.state.engagement[key] !== null){
                    if (typeof context.state.engagement[key] === "boolean") {
                        prev[key] = context.state.engagement[key] ? 1 : 0;
                    }
                    else {
                        prev[key] = context.state.engagement[key];
                    }
                }
                return prev;
            }, {});
            request.stakeholder_id = context.state.stakeholder.id;
            request.project_id = context.state.project.id;
            request.client_id = context.state.client.id;

            if (typeof request.id !== "undefined") {
                return axios.put(config().baseAPIUrl + config().updateEngagementUrl + "/" +request.id, qs.stringify(request)).then((response) => {
                    context.commit('setEngagementIsDirty', false);
                    return response;
                });
            }
            else {
                request.originated = ORIGINATED;
                request.date_recorded = Vue.moment().utc().toISOString();
                return axios.post(config().baseAPIUrl + config().storeEngagementUrl, qs.stringify(request)).then((response) => {
                    context.commit('setEngagementId', response.data.data.id);
                    context.commit('setEngagementIsDirty', false);
                    return response;
                });
            }
        },
        saveStakeholder(context) {
            if (!context.state.stakeholderIsDirty) {
                return Promise.resolve(context.state.stakeholder);
            }
            const request = Object.keys(context.state.stakeholder).reduce((prev, key) => {
                if (context.state.stakeholder[key] !== null){
                    if (typeof context.state.stakeholder[key] === "boolean") {
                        prev[key] = context.state.stakeholder[key] ? 1 : 0;
                    }
                    else {
                        prev[key] = context.state.stakeholder[key];
                    }
                }
                return prev;
            }, {});
            request.project_id = context.state.project.id;
            request.client_id = context.state.client.id;
            request.distribution_lists = {
                subscribe: context.state.stakeholder.distribution_lists.reduce((lists, list) => {
                    if (! context.state.distributionListsOriginal.some((original) => {
                        return list.id === original.id;
                    })) {
                        lists.push(list.id);
                        return lists;
                    }
                }, []),
                unsubscribe: context.state.distributionListsOriginal.reduce((lists, list) => {
                    if (! context.state.stakeholder.distribution_lists.some((original) => {
                        return list.id === original.id;
                    })) {
                        lists.push(list.id);
                        return lists;
                    }
                }, [])
            }
            console.log(request.distribution_lists)

            if (typeof request.id !== "undefined") {
                return axios.put(config().baseAPIUrl + config().updateStakeholderUrl + "/" + request.id, qs.stringify(request)).then((response) => {
                    context.commit('setPreviousStakeholder', context.state.stakeholder);
                    context.commit('setStakeholderIsDirty', false);
                    context.commit('setDistributionListsOriginal', context.state.stakeholder.distribution_lists);
                    return response.data;
                });
            }
            else{
                request.originated = ORIGINATED;
                return axios.post(config().baseAPIUrl + config().storeStakeholderUrl, qs.stringify(request)).then((response) => {
                    context.commit('setStakeholderId', response.data.data.id);
                    context.commit('setPreviousStakeholder', context.state.stakeholder);
                    context.commit('setStakeholderIsDirty', false);
                    context.commit('setDistributionListsOriginal', context.state.stakeholder.distribution_lists);
                    return response.data;
                });
            }
        },
        saveEvent(context) {
            if (!context.state.eventIsDirty) {
                return Promise.resolve(context.state.event);
            }
            const request = Object.keys(context.state.event).reduce((prev, key) => {
                if (context.state.event[key] !== null){
                    if (typeof context.state.event[key] === "boolean") {
                        prev[key] = context.state.event[key] ? 1 : 0;
                    }
                    else {
                        prev[key] = context.state.event[key];
                    }
                }
                return prev;
            }, {});
            request.project_id = context.state.project.id;

            if (typeof request.id !== "undefined") {
                return axios.put(config().baseAPIUrl + config().updateEventUrl + "/" +request.id, qs.stringify(request)).then((response) => {
                    context.commit('setEventIsDirty', false);
                    return response.data;
                });
            }
            else {
                return axios.post(config().baseAPIUrl + config().storeEventUrl, qs.stringify(request)).then((response) => {
                    context.commit('setEventIsDirty', false);
                    context.commit('setEventId', response.data.data.id);
                    return response.data;
                });
            }
        },
        getStakeholdersBySearchParams(context, searchParams) {
            const request = {};
            if (searchParams.cancelToken) {
                request.cancelToken = searchParams.cancelToken;
                delete searchParams.cancelToken;
                request.params = searchParams;
            }
            else {
                request.params = searchParams;
            }
            Object.keys(request.params).forEach((key) => {
                if (key == 'address_street_name' && request.params[key].split(' ').length > 1) {
                    let lastIndex = request.params[key].lastIndexOf(' ');
                    request.params[key] = request.params[key].substring(0, lastIndex);
                }
            });
            request.params.client_id = context.state.client.id;
            return axios.get(config().baseAPIUrl + config().getStakeholdersUrl, request).then((response) => {
                response.data.data = response.data.data.map((stakeholder) => {
                    return flattenLocationModel(stakeholder);
                });
                context.commit('setPossibleStakeholders', response.data.data);
                context.commit('setPossibleStakeholdersPagination', response.data.meta);
                context.commit('setPreviousStakeholderRequest', searchParams);
                return response.data.data;
            }).catch((error) => {
                if (!axios.isCancel(error)) {
                    throw error;
                }
            });
        },
        getStakeholdersByAddress(context) {
            const addressKeys = ['address_unit_no', 'address_street_no', 'address_street_name', 
                'address_suburb','address_state', 'address_postcode', 'address_country'];
            const request = addressKeys.reduce((prev, key) => {
                if (typeof context.state.stakeholder[key] !== 'undefined' && 
                    context.state.stakeholder[key] !== null){

                    prev[key] = context.state.stakeholder[key];

                    if (key == 'address_street_name' && prev[key].split(' ').length > 1) {
                        let lastIndex = prev[key].lastIndexOf(' ');
                        prev[key] = prev[key].substring(0, lastIndex);
                    }
                }
                return prev;
            }, {});

            request.client_id = context.state.client.id;

            return axios.get(config().baseAPIUrl + config().getStakeholdersUrl, {
                    params: request
            }).then((response) => {
                response.data.data = response.data.data.map((stakeholder) => {
                    return flattenLocationModel(stakeholder);
                });
                context.commit('setPossibleStakeholders', response.data.data);
                context.commit('setPossibleStakeholdersPagination', response.data.meta);
                context.commit('setPreviousStakeholderRequest', request);
                return response.data.data;
            });
        },
        getStakeholdersByPhone(context) {
            const request = {
                phone: context.state.stakeholder.phone,
                client_id: context.state.client.id
            };

            return axios.get(config().baseAPIUrl + config().getStakeholdersUrl, {
                params: request
            }).then((response) => {
                response.data.data = response.data.data.map((stakeholder) => {
                    return flattenLocationModel(stakeholder);
                });
                context.commit('setPossibleStakeholders', response.data.data);
                context.commit('setPossibleStakeholdersPagination', response.data.meta);
                context.commit('setPreviousStakeholderRequest', request);
                return response.data.data; 
            });
        },
        getStakeholdersByPagination(context, page) {
            const request = context.state.previousStakeholderRequest;
            request.page = page;

            return axios.get(config().baseAPIUrl + config().getStakeholdersUrl, {
                params: request
            }).then((response) => {
                response.data.data = response.data.data.map((stakeholder) => {
                    return flattenLocationModel(stakeholder);
                });
                context.commit('setPossibleStakeholders', response.data.data);
                context.commit('setPossibleStakeholdersPagination', response.data.meta);
                return response.data.data; 
            });
        },
        getTodaysEvents(context, type) {
            const request = {
                filter: {
                    project_id: context.state.project.id,
                    preset: EVENT_RESULTS_TODAY
                },
                timezone: moment.tz.guess()
            };
            if (type) {
                request.filter.type = type;
            }
            return axios.get(config().baseAPIUrl + config().getEventsUrl, {
                params: request,
                paramsSerializer: qs.stringify
            }).then((response) => {
                response.data.data = response.data.data.map((event) => {
                    return flattenLocationModel(event);
                });
                context.commit('setPossibleEvents', response.data.data);
                return response.data.data;
            });
        },
        getPortalUrl(context) {
            const request = {
                client_id: context.state.client.id,
                project_id: context.state.project.id,
                stakeholder_id: context.state.stakeholder.id
            };

            return axios.get(config().baseAPIUrl + config().getPortalUrl, {
                params: request
            }).then((response) => {
                return response.data.data;
            });
        },
        incrementManualCount(context, amount) {
            return axios.patch(config().baseAPIUrl + config().patchIncrementEventUrl+"/"+context.state.event.id+"/increment/"+amount).then((response) => {
                context.commit('incrementManualCount', amount);
                return response.data.data;
            });
        },
        decrementManualCount(context, amount) {
            return axios.patch(config().baseAPIUrl + config().patchDecrementEventUrl+"/"+context.state.event.id+"/decrement/"+amount).then((response) => {
                context.commit('decrementManualCount', amount);
                return response.data.data;
            });
        },
        mergeStakeholder(context, stakeholderId) {
            const request = {
                stakeholder1: context.state.stakeholder.id,
                stakeholder2: stakeholderId
            };

            return axios.post(config().baseAPIUrl + config().mergeStakeholderUrl, qs.stringify(request)).then((response) => {
                context.commit('setStakeholder', response.data.data);
                context.commit('setEngagementStakeholderId', response.data.data.id);
                context.commit('setPreviousStakeholder', context.state.stakeholder);
                context.commit('setStakeholderIsDirty', false);
            })
        },
        getProjects(context, page) {
            const request = {
                limit: 20,
                includes: 'client',
                sorts: 'client_id,name'
            }
            if (page) {
                request.page = page;
            }
            return axios.get(config().baseAPIUrl + config().getUsersUrl + "/" + context.state.user.id + "/projects", {
                params: request,
                paramsSerializer: qs.stringify
            }).then((response) => {
                context.commit('setProjects', response.data.data);
                context.commit('setProjectsPagination', response.data.meta);
                return response.data.data;
            });
        },
        getDistributionLists(context) {
            const request = {
                filter: {
                    project_id: context.state.project.id
                },
                limit: 99
            }
            return axios.get(config().baseAPIUrl + config().getDistributionListsUrl, {
                params: request,
                paramsSerializer: qs.stringify
            }).then((response) => {
                context.commit('setDistributionLists', response.data.data);
                return response.data.data;
            });
        },
        getForm(context, id) {
            return axios.get(config().baseAPIUrl + config().getFormUrl+'/'+id)
                .then((response) => {
                    context.commit('setForm', response.data.data);
                    return response.data.data;
                });
        },
        submitForm(context, payload) {
            const request = {
                form_uuid: context.state.form.id,
                stakeholder_uuid: context.state.stakeholder.id,
                user_uuid: context.state.user.id,
                event_uuid: context.state.event.id,
                stakeholder: {},
                engagement: {
                    name: 'CA Canvass form submission during '+context.state.event.name +' event',
                    originated: 'canvass',
                    method: 'Doorknock'
                },
                form: payload.data
            };

            return axios.post(config().baseAPIUrl + config().formSubmissionUrl, request).then((response) => {
                return response;
            })
        }
    }
})
