<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>
            Edit stakeholder information
        </page-heading>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group
                    label="Email:"
                    label-for="stakeholder-email"
                >
                    <b-form-input
                    id="stakeholder-email"
                    v-model="localStakeholder.email"
                    type="email"
                    @blur.native="checkEmail"
                    required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Subscriptions:"
                    label-for="stakeholder-subscriptions"
                >
                    <multiselect id="stakeholder-subscriptions"
                        :multiple="true" 
                        :options="distributionListOptions"
                        label="text"
                        track-by="value"
                        :close-on-select="false"
                        v-model="subscribeList"
                        placeholder="Subscribe to newsletter " >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Home phone:"
                    label-for="stakeholder-home-phone"
                >
                    <b-form-input
                    id="stakeholder-home-phone"
                    v-model="localStakeholder.home_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Mobile phone:"
                    label-for="stakeholder-mobile-phone"
                >
                    <b-form-input
                    id="stakeholder-mobile-phone"
                    v-model="localStakeholder.mobile_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="First name:"
                    label-for="stakeholder-first-name"
                >
                    <b-form-input
                    id="stakeholder-first-name"
                    v-model="localStakeholder.first_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Last name:"
                    label-for="stakeholder-last-name"
                >
                    <b-form-input
                    id="stakeholder-last-name"
                    v-model="localStakeholder.last_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group
                    label="Business name:"
                    label-for="stakeholder-business-name"
                >
                    <b-form-input
                    id="stakeholder-business-name"
                    v-model="localStakeholder.business_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="2">
                <b-form-group
                    label="Unit #:"
                    label-for="stakeholder-unit-no"
                >
                    <b-form-input
                    id="stakeholder-unit-no"
                    v-model="localStakeholder.address_unit_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group
                    label="Street #:"
                    label-for="stakeholder-street-no"
                >
                    <b-form-input
                    id="stakeholder-street-no"
                    v-model="localStakeholder.address_street_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Street name:"
                    label-for="stakeholder-street-name"
                >
                    <b-form-input
                    id="stakeholder-street-name"
                    v-model="localStakeholder.address_street_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Suburb:"
                    label-for="stakeholder-suburb"
                >
                    <b-form-input
                    id="stakeholder-suburb"
                    v-model="localStakeholder.address_suburb"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Postcode:"
                    label-for="stakeholder-postcode"
                >
                    <b-form-input
                    id="stakeholder-postcode"
                    v-model="localStakeholder.address_postcode"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="State:"
                    label-for="stakeholder-state"
                >
                    <b-form-input
                    id="stakeholder-state"
                    v-model="localStakeholder.address_state"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Country:"
                    label-for="stakeholder-country"
                >
                    <b-form-input
                    id="stakeholder-country"
                    v-model="localStakeholder.address_country"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row v-if="useRMS" align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group
                    label="Notes:"
                    label-for="stakeholder-notes"
                >
                    <b-form-textarea
                    id="stakeholder-notes"
                    v-model="notes"
                    type="text"
                    ></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>

        <b-modal ref="email-check-modal" :hide-footer="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
            <template slot="modal-header">
                <h4>A stakeholder already exists with this email address. What would you like to do?</h4>
            </template>
            <card-list :items="matchingStakeholders" keyName="id" :selectItem="mergeStakeholder" :sizing={sm:12,md:12,lg:12,xl:12}>
                <template v-slot="matchingStakeholder">
                    <h4 v-if="matchingStakeholder.first_name || matchingStakeholder.last_name">
                        Merge with {{matchingStakeholder.first_name}} {{matchingStakeholder.last_name}}
                    </h4>
                    <h4 v-else>
                        Merge with Anonymous
                    </h4>
                    <p v-if="matchingStakeholder.email">
                        {{matchingStakeholder.email}}
                    </p>
                    <p v-else>
                        No email given
                    </p>
                    <p v-if="matchingStakeholder.phone || matchingStakeholder.mobile_phone || matchingStakeholder.home_phone">
                        {{matchingStakeholder.phone ? matchingStakeholder.phone : (matchingStakeholder.mobile_phone ? matchingStakeholder.mobile_phone : matchingStakeholder.home_phone)}}
                    </p>
                    <p v-else>
                        No phone number given
                    </p>
                    <p v-if="addressToShortString(matchingStakeholder)">
                        {{addressToShortString(matchingStakeholder)}}
                    </p>
                    <p v-else>
                        No address given
                    </p>
                </template>
            </card-list>
            <card-button variant="primary" title="Continue and don't merge" :onClick="dontMerge">
            </card-button>
        </b-modal>

        <footer-button
        title="Continue"
        :onClick="checkStakeholder"
        @mousedown.native="cancelBlur"
        >
        </footer-button>
    </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect';
import CardList from '@/components/CardList.vue';
import CardButton from '@/components/CardButton.vue';
import _ from 'lodash';
export default {
    components: {
        'spinner': Spinner,
        'multiselect': Multiselect,
        'card-list': CardList,
        'card-button': CardButton
    },
    props: {
        nextUrl: {
            type: Object,
            default: (() => {
                return {};
            })
        },
        prefilled: {
            type: Object,
            default: (() => {
                return {};
            })
        }
    },
    data() {
        return {
            localStakeholder: {},
            emailChecked: false,
            matchingStakeholders: [],
            continueToSave: false,
            subscribeList: [],
            notes: "",
            preventEmailCheck: false
        }
    },
    computed: {
        ...mapState([
            'distributionLists',
            'distributionListsOriginal'
        ]),
        ...mapGetters([
            'distributionListOptions'
        ]),
        email() {
            return this.localStakeholder.email;
        },
        subscriptionOptions() {
            if (this.useSydMetroWest) {
                return this.getSydMetroSubscriptionOptions();
            }
            else if (this.useRMS) {
                return this.getRMSSubscriptionOptions();
            }
            return [];
        }
    },
    methods: {
        cancelBlur() {
            this.preventEmailCheck = true;
        },
        checkStakeholder() {
            if (this.localStakeholder.email && !this.emailChecked) {
                this.continueToSave = true;
                this.preventEmailCheck = false;
                this.checkEmail().then((stakeholdersToMerge) => {
                    if (!stakeholdersToMerge) {
                        this.saveStakeholder();
                    }
                })
            }
            else{
                this.saveStakeholder();
            }
        },
        saveStakeholder() {
            this.$store.commit('setStakeholder', this.localStakeholder);

            if (this.useRMS) {
                this.$store.commit('setEngagementName', this.notes);
            }

            this.processing = true;
            this.$store.dispatch('saveStakeholder')
            .then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                return this.$store.dispatch('saveEngagement')
            }).then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                this.$store.commit('setStakeholderEmailChecked', true);
                if (this.useRMS && this.areDoorknocking) {
                    const event = _.clone(this.communityEvent);
                    const method = this.engagement.method;
                    const form = this.eventForm;
                    this.$store.commit('resetStore');
                    this.$store.commit('setEvent', event)
                    this.$store.commit('setEngagementMethod', method)
                    this.$store.commit('setForm', form);
                    this.$router.push('/doorknock/counter');
                }   
                else if (Object.keys(this.nextUrl).length) {
                    this.$router.push(this.nextUrl);
                }
                else if (this.areDoorknocking) {
                    this.$router.push('/doorknock/extra');
                }
                else if (this.isEvent) {
                    this.$router.push('/eventfeedback/extra');
                }
                else if (this.areCalling) {
                    this.$router.push('/phonecall/extra');
                }
            }).finally(() => {
                this.processing = false;
            });
        },
        checkEmail() {
            if (this.preventEmailCheck) {
                this.preventEmailCheck = false;
                return;
            }
            if (this.localStakeholder.email != this.stakeholder.email) {
                this.emailChecked = false;
            }
            
            if (this.emailChecked || !this.localStakeholder.email) {
                return;
            }

            this.processing = true;
            return this.$http.get(this.$config.baseAPIUrl + this.$config.getStakeholdersUrl, {
                params: {
                    email: this.localStakeholder.email
                }
            }).then((response) => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                response.data.data = response.data.data.map((stakeholder) => {
                    return this.flattenLocationModel(stakeholder);
                });
                this.matchingStakeholders = response.data.data.filter((stakeholder) => {
                    return stakeholder.id != this.stakeholder.id;
                });
                if (this.matchingStakeholders.length) {
                    this.$refs['email-check-modal'].show();
                    return true;
                } else {
                    this.emailChecked = true;
                    return false;
                }
            }).finally(() => {
                this.processing = false;
            });
        },
        dontMerge() {
            this.emailChecked = true;
            this.$refs['email-check-modal'].hide();
            if (this.continueToSave) {
                this.continueToSave = false;
                this.saveStakeholder();
            }
        },
        mergeStakeholder(stakeholder) {
            this.$refs['email-check-modal'].hide();
            if (this.stakeholder.id === null) {
                this.emailChecked = true;
                const setValues = Object.keys(this.localStakeholder).reduce((prev, key) => {
                    if (this.localStakeholder[key] !== null) {
                        prev[key] = this.localStakeholder[key];
                    }
                    return prev;
                }, {})
                this.localStakeholder = Object.assign(stakeholder, setValues);
                if (this.continueToSave) {
                    this.continueToSave = false;
                    this.saveStakeholder();
                }
            }
            else {
                this.processing = true;
                this.$store.dispatch('mergeStakeholder', stakeholder.id).then(() => {
                    this.emailChecked = true;
                    this.localStakeholder = _.clone(this.stakeholder);
                    if (this.continueToSave) {
                        this.continueToSave = false;
                        this.saveStakeholder();
                    }
                }).finally(() => {
                    this.processing = false;
                });
            }
        }
    },
    created() {
        //to do check that this is a deep clone
        if (Object.keys(this.prefilled).length) {
            Object.assign(this.localStakeholder, this.prefilled);
            Object.assign(this.localStakeholder, this.prefilled.address);
            if (this.localStakeholder.phone && !this.localStakeholder.mobile_phone) {
                this.localStakeholder.mobile_phone = this.localStakeholder.phone;
            }
            if (this.localStakeholder.phone && !this.localStakeholder.home_phone) {
                this.localStakeholder.home_phone = this.localStakeholder.phone;
            }
        }
        else {
            this.localStakeholder = _.cloneDeep(this.stakeholder);
            this.localStakeholder.distribution_lists =this.distributionListsOriginal;
            this.subscribeList = this.localStakeholder.distribution_lists.map((list) => {
                return {
                    value: list.id,
                    text: list.name
                };
            });
        }

        this.emailChecked = this.$store.state.stakeholderEmailChecked;
    },
    watch: {
        subscribeList(val) {
            let lists = val.map((list) => {
                return this.distributionLists.find((dList) => {
                    return dList.id === list.value;
                });
            });
            
            this.localStakeholder.distribution_lists = lists;
        }
    }
}

</script>
