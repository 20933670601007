<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import _ from 'lodash';
const PACKAGE_JSON = process.env.VUE_APP_PACKAGE_JSON;
    export default {
        name: 'app',
        data() {
            return {
                geoWatcher: null,
                geoInterceptor: null,
                geoTimer: null
            }
        },
        methods: {
            transmitLocation(position) {
                this.$http.get(this.$config.baseAPIUrl + this.$config.positionPingUrl, {
                    headers: {
                        position: JSON.stringify(position)
                    }
                }).catch((error) => {
                    console.log('ping failed');
                });
            },
            geoSuccess(position) {
                let newPosition = {
                    timestamp: position.timestamp,
                    coords: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        altitude: position.coords.altitude,
                        accuracy: position.coords.accuracy,
                        altitudeAccuracy: position.coords.altitudeAccuracy,
                        heading: position.coords.heading,
                        speed: position.coords.speed
                    },
                    eventId: this.communityEvent.id
                }

                this.$store.commit('setPosition', newPosition);
            },
            geoError() {
                console.log('no geolocation available')
            },
            geoInterval() {
                if (this.$store.state.position != null) {
                    this.transmitLocation(this.$store.state.position);
                }
            },
            setupGeoWatcher() {
                const geo_options = {
                    enableHighAccuracy: true,
                };

                this.geoWatcher = navigator.geolocation.watchPosition(this.geoSuccess, this.geoError, geo_options);
                this.geoInterceptor = this.$http.interceptors.request.use(config => {
                    const allowedMethods = ['post','put','patch','delete'];

                    if (this.$store.state.position && allowedMethods.includes(config.method)) {
                        let payload = _.cloneDeep(this.$store.state.position);
                        config.headers['position'] = JSON.stringify(payload);
                    }

                    return config;
                });

                this.geoTimer = setInterval(this.geoInterval, this.$config.geoWatcherInterval);
            },
        },
        created() {
            this.$http.interceptors.response.use(response => {
                return response;
            }, error => {
                if (!error.response) {
                    if (!this.$http.isCancel(error)) {
                        this.$router.push('/error');
                    }
                    else {
                        console.log('Request cancelled');
                    }
                }
                else if (error.response.status === 401 && 
                    error.response.config.url != this.$config.baseAPIUrl + this.$config.loginUrl) {
                    this.signout();
                    this.$toasted.show('Your login has expired, please login again', {
                        position: 'top-right',
                        duration: null,
                        keepOnHover: true,
                        action : {
                            text : 'Close',
                            onClick : (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        },
                    });
                }
                else if (error.response.status === 409) {
                    console.warn('Caught 409 error - ' + error.response.data.message);
                }
                else {
                    this.$router.push('/error');
                }
                throw error;
            });

            this.$http.defaults.headers.common = {};

            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                this.signin(user);
                if (!this.$store.state.projects.length) {
                    this.$store.dispatch('getProjects');
                }
            }

            this.$router.afterEach(() => {
                this.$toasted.clear();
            });

            const version = JSON.parse(unescape(PACKAGE_JSON || '%7Bversion%3A0%7D')).version;
            if (this.$config.refreshVersion && this.$config.refreshVersion == version && this.$store.state.version != version) {
                this.signout();
                this.$toasted.show("You've been logged out due to an update, sorry for the inconvenience", {
                    position: "bottom-right", 
                    duration : null,
                    keepOnHover: true,
                    action : {
                        text : 'Close',
                        onClick : (e, toastObject) => {
                            toastObject.goAway(0);
                        }
                    },
                });
            }
            this.$store.commit('setVersion', version);
            this.$http.defaults.headers.common.version = version;

            if (this.trackGeoposition) {
                this.setupGeoWatcher()
            }
        },
        destroyed() {
            window.Echo.leaveChannel(this.$config.eventEchoVersionChannel);
        },
        watch: {
            trackGeoposition(newVal) {
                if (newVal) {
                    this.setupGeoWatcher();
                }
                else {
                    navigator.geolocation.clearWatch(this.geoWatcher);
                    if (this.geoInterceptor) {
                        this.$http.interceptors.request.eject(this.geoInterceptor);
                    }
                    clearInterval(this.geoTimer);
                }
            }
        }
    }
</script>

<style>
    @import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";
</style>

