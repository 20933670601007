export function addressToString(address) {

    let addressString = "";
    if (address.address_unit_no) {
        addressString += address.address_unit_no + "/";
    }
    if (address.address_street_no) {
        addressString += address.address_street_no + " ";
    }
    if (address.address_street_name) {
        addressString += address.address_street_name + ", ";
    }
    if (address.address_suburb) {
        addressString += address.address_suburb + " ";
    }
    if (address.address_state) {
        addressString += address.address_state + " ";
    }
    if (address.address_postcode) {
        addressString += address.address_postcode + ", ";
    }
    if (address.address_country) {
        addressString += address.address_country;
    }
    return addressString;
}

export function addressToShortString(address) {

    let addressString = "";
    if (address.address_unit_no) {
        addressString += address.address_unit_no + "/";
    }
    if (address.address_street_no) {
        addressString += address.address_street_no + " ";
    }
    if (address.address_street_name) {
        addressString += address.address_street_name;
    }
    return addressString;
}

export function stakeholderToFullName(stakeholder) {
    let name = "";
    if (stakeholder.first_name) {
        name += stakeholder.first_name;
    }
    if (stakeholder.last_name) {
        if (name.length) {
            name += ' ';
        }
        name += stakeholder.last_name;
    }
    if (name.length) {
        return name;
    } 
    return 'Anonymous';
}

const SENTIMENTS = [
    {
        label: 'Positive',
        value: 1,
        text: '😄 – Positive'
    },
    {
        label: 'Neutral',
        value: 0,
        text: '🤔 - Neutral'
    },
    {
        label: 'Negative',
        value: -1,
        text: '🙁 - Negative'
    }
];

const SYD_METRO_SENTIMENTS = [
    {
        label: 'Complaint - avoidable',
        value: -1,
        text: 'Complaint - avoidable'
    },
    {
        label: 'Complaint - not related to Sydney Metro',
        value: -1,
        text: 'Complaint - not related to Sydney Metro'
    },
    {
        label: 'Complaint - TBD',
        value: -1,
        text: 'Complaint - TBD'
    },
    {
        label: 'Complaint - Unavoidable',
        value: -1,
        text: 'Complaint - Unavoidable'
    },
    {
        label: 'Not related',
        value: 0,
        text: 'Not related'
    },
    {
        label: 'Compliment',
        value: 1,
        text: 'Compliment'
    },
    {
        label: 'Other',
        value: 0,
        text: 'Other'
    }
];

export function getSentiments(useSydMetroSentiments) {
    if (useSydMetroSentiments) {
        return SYD_METRO_SENTIMENTS;
    }

    return SENTIMENTS;
}

const SUBSCRIPTION_LABELS = {
    method: 'email',
    origin: 'canvass',
    enabled: 'subscribed',
    disabled: 'unsubscribed'
};

const SYD_METRO_SUBSCRIPTION_METHODS = [
    'bays_precinct',
    'burwood_north',
    'clyde_stabling',
    'five_dock',
    'haberfield',
    'lilyfield',
    'north_strathfield',
    'parramatta',
    'pyrmont',
    'rosehill',
    'rydalmere',
    'silverwater',
    'sydney_cbd_north',
    'sydney_cbd_south',
    'sydney_olympic_park',
    'westmead',
    'email'
];

const SYD_METRO_SUBSCRIPTION_OPTIONS = [
    {
        value: 'email',
        text: 'General Project Update'
    },
    {
        value: 'bays_precinct',
        text: 'Bays Precinct Station'
    },
    {
        value: 'burwood_north',
        text: 'Burwood North Station'
    },
    {
        value: 'clyde_stabling',
        text: 'Clyde Stabling'
    },
    {
        value: 'five_dock',
        text: 'Five Dock Station'
    },
    {
        value: 'haberfield',
        text: 'Haberfield Facility'
    },
    {
        value: 'lilyfield',
        text: 'Lilyfield Facility'
    },
    {
        value: 'north_strathfield',
        text: 'North Strathfield Station'
    },
    {
        value: 'parramatta',
        text: 'Parramatta Station'
    },
    {
        value: 'pyrmont',
        text: 'Pyrmont Station'
    },
    {
        value: 'rosehill',
        text: 'Rosehill Station'
    },
    {
        value: 'rydalmere',
        text: 'Rydalmere Station'
    },
    {
        value: 'silverwater',
        text: 'Silverwater Facility'
    },
    {
        value: 'sydney_cbd_north',
        text: 'Sydney CBD (North) Station'
    },
    {
        value: 'sydney_cbd_south',
        text: 'Sydney CBD (South) Station'
    },
    {
        value: 'sydney_olympic_park',
        text: 'Sydney Olympic Park Station'
    },
    {
        value: 'westmead',
        text: 'Westmead Station'
    }
];

const RMS_SUBSCRIPTION_METHODS = [
    'community',
    'airport',
    'freight'
];

const RMS_SUBSCRIPTION_OPTIONS = [
    {
        value: 'community',
        text: 'Community'
    },
    {
        value: 'airport',
        text: 'Airport precinct & businesses'
    },
    {
        value: 'freight',
        text: 'Freight industry'
    },
]

export function getSydMetroSubscriptionMethods() {
    return SYD_METRO_SUBSCRIPTION_METHODS;
}

export function getSydMetroSubscriptionOptions() {
    return SYD_METRO_SUBSCRIPTION_OPTIONS;
}

export function getRMSSubscriptionMethods() {
    return RMS_SUBSCRIPTION_METHODS;
}

export function getRMSSubscriptionOptions() {
    return RMS_SUBSCRIPTION_OPTIONS;
}

export function setStakeholderSubscription(stakeholder, enabled, project, method, useSydMetroSubscriptions) {
    let insertNewSubscription = true;
    if (typeof method == 'undefined') {
        method = SUBSCRIPTION_LABELS.method;
    }
    if (stakeholder.subscriptions === null || typeof stakeholder.subscriptions === 'undefined') {
        stakeholder.subscriptions = [];
    }
    stakeholder.subscriptions = stakeholder.subscriptions.map((subscription) => {
        if (subscription.method == method && 
            ((!useSydMetroSubscriptions && subscription.project_id == project.id) ||
            (useSydMetroSubscriptions && (subscription.project_id == project.project_id || subscription.project_id == project.id)))) {

            subscription.action = parseInt(enabled) ? SUBSCRIPTION_LABELS.enabled : SUBSCRIPTION_LABELS.disabled;
            insertNewSubscription =  false;
        }
        return subscription;
    });

    if (insertNewSubscription) {
        stakeholder.subscriptions.push({
            method: method,
            origin: SUBSCRIPTION_LABELS.origin,
            action: parseInt(enabled) ? SUBSCRIPTION_LABELS.enabled : SUBSCRIPTION_LABELS.disabled,
            project_id: useSydMetroSubscriptions ? project.project_id : project.id
        });
    }
}

export function getStakeholderSubscriptionStatus(stakeholder, project, method, useSydMetroSubscriptions) {
    let subscriptionStatus = null;
    if (typeof method == 'undefined') {
        method = SUBSCRIPTION_LABELS.method;
    }
    if (stakeholder.subscriptions === null || typeof stakeholder.subscriptions === 'undefined') {
        return subscriptionStatus;
    }
    stakeholder.subscriptions.forEach((subscription) => {
        if (subscription.method == method &&
            ((!useSydMetroSubscriptions && subscription.project_id == project.id) ||
            (useSydMetroSubscriptions && (subscription.project_id == project.project_id || subscription.project_id == project.id)))) {

            subscriptionStatus = (subscription.action == SUBSCRIPTION_LABELS.enabled) ? 1 : 0;
        }
    });

    return subscriptionStatus;
}

export function getStakeholderSubscriptions(stakeholder, project, useSydMetroSubscriptions, useRMSSubscriptions) {
    let subscriptionArray = [];
    if (stakeholder.subscriptions === null || typeof stakeholder.subscriptions === 'undefined') {
        return subscriptionArray;
    }
    subscriptionArray = stakeholder.subscriptions.filter((subscription) => {
        if ((!useSydMetroSubscriptions && subscription.project_id != project.id) ||
            (useSydMetroSubscriptions && subscription.project_id != project.project_id && subscription.project_id != project.id)) {

            return false;
        }
        if (useSydMetroSubscriptions) {
            if (SYD_METRO_SUBSCRIPTION_METHODS.includes(subscription.method)) {
                return true;
            }
        }
        if (useRMSSubscriptions) {
            if (RMS_SUBSCRIPTION_METHODS.includes(subscription.method)) {
                return true;
            }
        }
        if (subscription.method == SUBSCRIPTION_LABELS.method) {
            return true;
        }
        return false;
    });

    return subscriptionArray;
}

export function resetStakeholderSubscriptions(stakeholder, project, useSydMetroSubscriptions, useRMSSubscriptions) {

    stakeholder.subscriptions = stakeholder.subscriptions.filter((subscription) => {
        return ((!useSydMetroSubscriptions && subscription.project_id != project.id) ||
            (useSydMetroSubscriptions && subscription.project_id != project.project_id && subscription.project_id != project.id));
    });

    setStakeholderSubscription(stakeholder, 0, project, 'email', useSydMetroSubscriptions);
    if (useSydMetroSubscriptions) {
        SYD_METRO_SUBSCRIPTION_METHODS.forEach((method) => {
            setStakeholderSubscription(stakeholder, 0, project, method, useSydMetroSubscriptions);
        });
    }
    else if (useRMSSubscriptions) {
        RMS_SUBSCRIPTION_METHODS.forEach((method) => {
            setStakeholderSubscription(stakeholder, 0, project, method);
        })
    }
}

const EVENT_TYPES = [
    'Community engagement',
    'General public event/expo',
    'Pop up info session',
    'Industry event/expo',
    'Internal stakeholder engagement',
    'Other'
];

const SYD_METRO_EVENT_TYPES = [
    'Community engagement',
    'General public event/expo',
    'Pop up info session',
    'Industry event/expo',
    'Internal stakeholder engagement',
    'Presentation',
    'Community session',
    'Other'
];

export function getEventTypes(useSydMetroEventTypes) {
    if (useSydMetroEventTypes) {
        return SYD_METRO_EVENT_TYPES;
    }
    return EVENT_TYPES;
}

export const SUBSCRIPTION_METHOD = {
    method: '',
    text: 'Subscribe to updates',
    route: '/subscribe',
    icon: 'mail-bulk'
}

export const DOORKNOCK_METHOD = {
    method: 'Doorknock',
    text: 'Doorknocking',
    route: '/doorknock',
    icon: 'door-closed'
};

export const PHONE_METHOD = {
    method: 'Phone - outgoing',
    text: 'Phone call',
    route: '/phonecall',
    icon: 'phone-volume'
};

export const EVENT_FEEDBACK_METHOD = {
    method: 'Event',
    text: 'Event Sign-in/Feedback',
    route: '/eventfeedback',
    icon: 'comment-alt'
};

export const EVENT_ATTENDANCE_METHOD = {
    method: 'Event',
    text: 'Counting event attendance',
    route: '/eventcount',
    icon: 'user-check'
};

export const PRESENTATION_METHOD = {
    method: 'Presentation',
    text: 'Presentation',
    route: '/eventfeedback',
    icon: 'comment-alt'
};

export const COMMUNITY_INFORMATION_SESSION_METHOD = {
    method: 'Community session',
    text: 'Community Information Session',
    route: '/eventcount',
    icon: 'user-check'
};

export function flattenLocationModel(model) {
    return {
        ...model,
        ...model.location,
        location: model.location.name,
        name: model.name
    };
}
