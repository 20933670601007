<template>
    <div class="engagement-method">
        <page-heading>
            What happened on this call?
        </page-heading>

        <card-list :items="outcomes" keyName="key" :selectItem="saveOutcome">
            <template v-slot="outcome">
                <h4>{{ outcome.text }}</h4>
            </template>
        </card-list>

        <!--<stakeholder-accordion 
            title="Learn more about the stakeholders with this phone number"
            :stakeholders="possibleStakeholders">
        </stakeholder-accordion>-->
    </div>
</template>

<script>
import CardList from "@/components/CardList.vue";
import { mapState } from 'vuex';
export default {
    name: 'DoorknockOutcome',
    components: {
        "card-list": CardList
    },
    data() {
        return {

            // phone
            outcomes: [
                {
                    text: 'Incoming call',
                    key: 4,
                    route: '/stakeholder/select'
                },
                {
                    text: 'No answer, no voicemail',
                    key: 5,
                    route: '/stakeholder/select'
                },
                {
                    text: 'No answer, voicemail left',
                    key: 6,
                    route: '/stakeholder/select'
                },
                {
                    text: 'Answered, not interested',
                    key: 7,
                    route: '/stakeholder/select'
                },
                {
                    text: 'Answered, interested',
                    key: 8,
                    route: '/stakeholder/select'
                }
            ]
        }
    },
    computed: {
        ...mapState([
            'possibleStakeholders'   
        ]),
        stakeholderPhone: function() {
            return this.stakeholder.phone;
        }
    
    },
    methods: {
        saveOutcome(outcome) {
            this.$store.commit('setOutcome', outcome.text);
            this.$router.push(outcome.route);
        }
    }
}

</script>
