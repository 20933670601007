<template>
    <b-container>
        <router-view></router-view>
    </b-container>
</template>

<script>

export default {
}

</script>
