<template>
    <b-row id="pageTitle" align-h="center">
        <b-col>
            <div id="back" v-if="displayBack">
                <a @click.prevent="goBack">
                    <font-awesome-icon icon="angle-left"></font-awesome-icon>
                </a>
            </div>
            <h2>
                <slot></slot>
            </h2>
            <h4>
                <slot name="subHeading">

                </slot>
            </h4>
        </b-col>
    </b-row>
</template>

<script>

export default {
    name: 'PageHeading',
    props: {
        displayBack: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}

</script>

