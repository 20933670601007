<template>
<b-row align-h="center">
    <b-col sm="12" md="10" lg="10" xl="10">
        <b-pagination
            v-if="moreThanOnePage"
            v-model="currentPage"
            align="fill"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            :limit="limit"
            hide-goto-end-buttons
        ></b-pagination>
    </b-col>
</b-row>
</template>


<script>
export default {
    props: {
        pagination: {
            type: Object,
            required: true
        },
        offset: {
            type: Number,
            default: 3
        }
    },
    data() {
        return {
            currentPage: 1
        }
    },
    computed: {
        limit() {
            return (2 * this.offset) + 1;
        },
        moreThanOnePage() {
            if (this.pages && this.pages.length && this.pages.length > 1) {
                return true;
            }

            return false;
        },
        pages() {
            let pages = [];
            if(!Object.keys(this.pagination)) {
                return pages;
            }
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            let to = from + this.offset - 1;
            if (to > this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            while (from <= to) {
                pages.push(from);
                from++;
            }
            return pages;
        }
    },
    created() {
        this.currentPage = this.pagination.current_page;
    },
    watch: {
        currentPage(val) {
            this.$emit('pageChange', val);
        }
    }
}
</script>

<style lang="scss">
@import "../variables.scss";
.page-item.active .page-link{
    background-color: $secondary;
    border-color: $secondary;
}
.page-link {
    color: $secondary;
}
</style>
