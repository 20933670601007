<template>
    <b-row class="sub-heading" align-h="center">
        <b-col>
            <h3>
                <slot></slot>
            </h3>
        </b-col>
    </b-row>
</template>

<script>

export default {
    name: 'SubHeading',
    props: {
        displayBack: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}

</script>

<style lang="scss" scoped>
#pageTitle {
    position: relative;
}
#back {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
h2 { 
    margin: 1rem; 
}
</style>

