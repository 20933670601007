<template>
<div>
    <page-heading>{{ communityEvent.name }}: Attendance</page-heading>

    <counter></counter>

    <b-row align-h="center" >
        <b-col xs="12" md="10" lg="10" xl="10">
            <card-button
            title="Finished"
            variant="primary"
            :onClick="()=>{ this.$router.push('/') }">
            </card-button>
        </b-col>
    </b-row>

    <footer-button
    title="Add feedback"
    :onClick="()=>{ this.$router.push('/eventfeedback/search') }"
    >
    </footer-button>
</div>
</template>

<script>
import Counter from '@/components/Counter';

export default {
    components: {
        counter: Counter
    },
}
</script>
