<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>
            Enter / Confirm stakeholder information
        </page-heading>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="stakeholder-email">
                        Email: <span class="badge-req badge badge-danger">Required</span>
                    </label>
                    <multiselect v-model="searchStakeholder" id="stakeholder-email" 
                    label="display_name" track-by="id" 
                    placeholder="Search emails" 
                    tag-placeholder="Create new stakeholder with this email"
                    open-direction="bottom" 
                    :options="searchedStakeholders" 
                    :multiple="false" 
                    :taggable="true"
                    :searchable="true" 
                    :loading="searchProcessing" 
                    :internal-search="false" 
                    :clear-on-select="false" 
                    :close-on-select="true" 
                    :options-limit="300" 
                    :limit="5"
                    :limit-text="limitText"
                    :max-height="600" 
                    :show-no-results="false" 
                    :hide-selected="true" 
                    :preserve-search="true"
                    @search-change="getStakeholdersByEmail"
                    @tag="createNewStakeholder">
                        <span slot="noResult">Oops! No stakeholders found. Consider changing the search query.</span>
                    </multiselect>

                </b-form-group>
                <b-form-group v-if="distributionListOptions.length > 1"
                    label="Subscriptions:"
                    label-for="stakeholder-subscriptions"
                >
                    <multiselect id="stakeholder-subscriptions"
                        :multiple="true" 
                        :options="distributionListOptions"
                        label="text"
                        track-by="value"
                        v-model="subscribeList"
                        :close-on-select="false"
                        placeholder="Subscribe to newsletter " >
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Home phone:"
                    label-for="stakeholder-home-phone"
                >
                    <b-form-input
                    id="stakeholder-home-phone"
                    v-model="localStakeholder.home_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Mobile phone:"
                    label-for="stakeholder-mobile-phone"
                >
                    <b-form-input
                    id="stakeholder-mobile-phone"
                    v-model="localStakeholder.mobile_phone"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="First name:"
                    label-for="stakeholder-first-name"
                >
                    <b-form-input
                    id="stakeholder-first-name"
                    v-model="localStakeholder.first_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Last name:"
                    label-for="stakeholder-last-name"
                >
                    <b-form-input
                    id="stakeholder-last-name"
                    v-model="localStakeholder.last_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="2">
                <b-form-group
                    label="Unit #:"
                    label-for="stakeholder-unit-no"
                >
                    <b-form-input
                    id="stakeholder-unit-no"
                    v-model="localStakeholder.address_unit_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group
                    label="Street #:"
                    label-for="stakeholder-street-no"
                >
                    <b-form-input
                    id="stakeholder-street-no"
                    v-model="localStakeholder.address_street_no"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Street name:"
                    label-for="stakeholder-street-name"
                >
                    <b-form-input
                    id="stakeholder-street-name"
                    v-model="localStakeholder.address_street_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Suburb:"
                    label-for="stakeholder-suburb"
                >
                    <b-form-input
                    id="stakeholder-suburb"
                    v-model="localStakeholder.address_suburb"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Postcode:"
                    label-for="stakeholder-postcode"
                >
                    <b-form-input
                    id="stakeholder-postcode"
                    v-model="localStakeholder.address_postcode"
                    inputmode="numeric"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="State:"
                    label-for="stakeholder-state"
                >
                    <b-form-input
                    id="stakeholder-state"
                    v-model="localStakeholder.address_state"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Country:"
                    label-for="stakeholder-country"
                >
                    <b-form-input
                    id="stakeholder-country"
                    v-model="localStakeholder.address_country"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <footer-button
        title="Subscribe"
        :onClick="saveStakeholder"
        :disabled="!localStakeholder.email"
        >
        </footer-button>
    </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Spinner from '@/components/Spinner.vue';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        'spinner': Spinner,
        'multiselect': Multiselect
    },
    data() {
        return {
            localStakeholder: {},
            searchStakeholder: null,
            searchesRunning: 0,
            cancel: null,
            searchedStakeholders: [],
            selectValue: '',
            subscribeList: [],
        }
    },
    computed: {
        ...mapState([
            'distributionLists',
            'distributionListsOriginal'
        ]),
        ...mapGetters([
            'distributionListOptions'
        ]),
        searchProcessing() {
            return this.searchesRunning > 0;
        },
        subscriptionOptions() {
            if (this.useSydMetroWest) {
                return this.getSydMetroSubscriptionOptions();
            }
            else if (this.useRMS) {
                return this.getRMSSubscriptionOptions();
            }
            return [];
        }
    },
    methods: {
        saveStakeholder() {
            this.$store.commit('setStakeholder', this.localStakeholder);
            this.processing = true;
            this.$store.dispatch('saveStakeholder')
            .then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                this.$router.push('/');
            }).finally(() => {
                this.processing = false;
            });
        },
        async getStakeholdersByEmail( query ) {
            const request = {
                email: query
            };
            
            if (this.cancel) {
                this.cancel.cancel();
            }
            this.searchesRunning ++;
            this.cancel = this.$http.CancelToken.source();
            this.$http.get(this.$config.baseAPIUrl + this.$config.getStakeholdersUrl, {
                params: request,
                cancelToken: this.cancel.token
            }).then((response) => {
                this.searchedStakeholders = response.data.data.map((result) => {
                    result = this.flattenLocationModel(result);
                    result.display_name = result.email + ' (';
                    if (result.first_name) {
                        result.display_name += result.first_name;
                        if (result.last_name) {
                            result.display_name += ' ' + result.last_name;
                        }
                    }
                    else {
                        result.display_name += 'Anonymous';
                    }
                    result.display_name += ')';
                    return result;
                })
            }).catch((error) => {
                if (!this.$http.isCancel(error)) {
                    throw error;
                }
            }).finally(() => {
                this.searchesRunning --;
            });
        },
        limitText (count) {
            return `and ${count} other stakeholders`
        },
        createNewStakeholder( query ) {
            const newStakeholder = {
                id: null,
                email: query,
                display_name: query + ' (new stakeholder)'
            };

            this.searchStakeholder = newStakeholder;
        }
    },
    watch: {
        'searchStakeholder.email': function() {
            if (this.searchStakeholder === null) {
                return;
            }
            this.localStakeholder = Object.keys(this.localStakeholder).reduce((prev, key) => {
                if (key === 'distribution_lists') {
                    prev[key] = this.searchStakeholder[key].data;
                    this.subscribeList = this.searchStakeholder.distribution_lists.data.map((list) => {
                        return {
                            value: list.id,
                            text: list.name
                        };
                    });
                }
                else if (typeof this.searchStakeholder[key] !== 'undefined') {
                    prev[key] = this.searchStakeholder[key];
                }
                else {
                    prev[key] = null;
                }
                return prev;
            }, {})
            
            if (this.distributionLists.length === 1) {
                this.subscribeList = this.distributionLists.map((list) => {
                    return {
                        value: list.id,
                        text: list.name
                    };
                });
            }
        },
        subscribeList(val) {
            let lists = val.map((list) => {
                return this.distributionLists.find((dList) => {
                    return dList.id === list.value;
                });
            });
            this.localStakeholder.distribution_lists = lists;
        }
    },
    created() {
        this.$store.commit('resetStore');
        this.localStakeholder = this.stakeholder;
        this.localStakeholder.distribution_lists = this.distributionListsOriginal;
        this.subscribeList = this.localStakeholder.distribution_lists.map((list) => {
            return {
                value: list.id,
                text: list.name
            };
        });

        if (this.distributionLists.length === 1) {
            this.subscribeList = this.distribution_lists.map((list) => {
                return {
                    value: list.id,
                    text: list.name
                };
            });
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import "../variables.scss";
.create-new {
    background-color: $secondary;
}
.create-new:hover {
    background-color: $secondary;
}
</style>

