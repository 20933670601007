/**
 * Canvas Mixin - Application wide utilities
 */

import { mapState, mapGetters } from 'vuex';
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import _ from 'lodash';
import { uuid } from 'uuidv4';
import { addressToString, addressToShortString, stakeholderToFullName, getSentiments, setStakeholderSubscription, 
    getStakeholderSubscriptions, getStakeholderSubscriptionStatus, getSydMetroSubscriptionMethods, getRMSSubscriptionMethods, resetStakeholderSubscriptions, 
    getSydMetroSubscriptionOptions, getRMSSubscriptionOptions, getEventTypes, flattenLocationModel, DOORKNOCK_METHOD, PHONE_METHOD, EVENT_ATTENDANCE_METHOD, EVENT_FEEDBACK_METHOD,
    PRESENTATION_METHOD, COMMUNITY_INFORMATION_SESSION_METHOD, SUBSCRIPTION_METHOD } from '../plugins/helpers';
export const CanvasMixin = {
    data() {
        return {
            processing: false,
            DOORKNOCK_METHOD,
            PHONE_METHOD,
            EVENT_ATTENDANCE_METHOD,
            EVENT_FEEDBACK_METHOD,
            PRESENTATION_METHOD, 
            COMMUNITY_INFORMATION_SESSION_METHOD,
            SUBSCRIPTION_METHOD,
            isAlive: false,
            componentForm: {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            },
            componentMap: {
                street_number: 'address_street_no',
                route: 'address_street_name',
                locality: 'address_suburb',
                administrative_area_level_1: 'address_state',
                country: 'address_country',
                postal_code: 'address_postcode',
            },
        }
    },
    computed: {
        ...mapState({
            stakeholder: 'stakeholder',
            engagement: 'engagement',
            communityEvent: 'event',
            project: 'project',
            publicMode: 'publicMode',
            areCountingEventAttendance: 'areCountingEventAttendance',
            eventForm: 'form'
        }),
        ...mapGetters({
            stakeholderAddress: 'stakeholderAddress',
            shortStakeholderAddress: 'shortStakeholderAddress'
        }),
        useSydMetroWest() {
            return this.$config.sydMetroWestEnabled && 
                (this.$config.sydMetroWestProjectId == this.$store.state.project.id || 
                    this.$config.sydMetroWestProjectId == this.$store.state.project.project_id);
        },
        useRMS() {
            return this.$config.rmsEnabled && this.$config.rmsProjectId == this.$store.state.project.id;
        }, 
        navigationMethods() {
            let methodList = [
                this.DOORKNOCK_METHOD
            ];

            if (this.useSydMetroWest) {
                methodList = methodList.concat([
                    this.PRESENTATION_METHOD,
                    this.COMMUNITY_INFORMATION_SESSION_METHOD
                ]);
            }
            else {
                methodList = methodList.concat([
                    this.EVENT_FEEDBACK_METHOD,
                    this.EVENT_ATTENDANCE_METHOD
                ]);
            }

            methodList = methodList.concat([
                this.SUBSCRIPTION_METHOD,
                this.PHONE_METHOD
            ]);

            return methodList;
        },
        isEvent() {
            if (this.engagement.method == this.EVENT_FEEDBACK_METHOD.method ||
                this.engagement.method == this.PRESENTATION_METHOD.method ||
                this.engagement.method == this.COMMUNITY_INFORMATION_SESSION_METHOD.method) {
                
                return true;
            }
            return false;
        },
        areDoorknocking() {
            if (this.engagement.method == this.DOORKNOCK_METHOD.method) {
                return true;
            }
            return false;
        },
        areCalling() {
            if (this.engagement.method == this.PHONE_METHOD.method) {
                return true;
            }
            return false;
        },
        engagementVerbFuture() {
            if (this.isEvent) {
                return 'sign-in';
            }
            if (this.areDoorknocking) {
                return 'doorknock';
            }
            if (this.areCalling) {
                return 'call';
            }
            return '';
        },
        engagementVerbPresent() {
            if (this.isEvent) {
                return 'signing-in';
            }
            if (this.areDoorknocking) {
                return 'doorknocking';
            }
            if (this.areCalling) {
                return 'calling';
            }
            return '';
        },
        engagementVerbPast() {
            if (this.isEvent) {
                return 'signed-in';
            }
            if (this.areDoorknocking) {
                return 'doorknocked';
            }
            if (this.areCalling) {
                return 'called';
            }
            return '';
        },
        trackGeoposition() {
            return this.areDoorknocking && this.communityEvent.id != null;
        }
    },
    methods: {
        getSentiments,
        addressToString,
        addressToShortString,
        stakeholderToFullName,
        setStakeholderSubscription,
        getStakeholderSubscriptions,
        getStakeholderSubscriptionStatus,
        getSydMetroSubscriptionMethods,
        getRMSSubscriptionMethods,
        resetStakeholderSubscriptions,
        getSydMetroSubscriptionOptions,
        getRMSSubscriptionOptions,
        getEventTypes,
        flattenLocationModel,
        signout() {
            localStorage.removeItem('user');
            this.$store.commit('setClient', null);
            this.$store.commit('setProject', null);
            this.$store.commit('setUser', null);
            this.$store.commit("setDistributionLists", []);
            this.$router.push('/login');
            this.$store.commit('setSessionId', null);
        },
        signin(userData) {
            if (userData.data) {
                if (userData.data.client) {
                    this.$store.commit('setClient', userData.data.client)
                }
                if (userData.data.project) {
                    this.$store.commit('setProject', userData.data.project);
                }
                if (userData.data.user) {
                    this.$store.commit('setUser', userData.data.user);
                }
                if (userData.data.provider) {
                    this.$store.commit('setProvider', userData.data.provider);
                }
                if (userData.data.providerUrl) {
                    this.$store.commit('setProviderUrl', userData.data.providerUrl);
                }
            }

            this.$store.commit('setAuthToken', userData.access_token);
            this.$http.defaults.headers.common = {'Authorization': `Bearer ${userData.access_token}`}

            let sessionId = this.$store.state.sessionId || uuid();
            
            this.$store.commit('setSessionId', sessionId);
            this.$http.defaults.headers.common['session-id'] = sessionId;

            
            window.Echo = new Echo({
                broadcaster: 'socket.io',
                host: this.$config.eventEchoHost,
                auth: {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.state.authToken
                    }
                },
                client: io
            });

            window.Echo.channel(this.$config.eventEchoVersionChannel)
            .listen('CanvasUpdated', this.handleVersionUpdate);
            
            if (this.$store.state.project) {
                this.$store.dispatch('getDistributionLists');
            }
        },
        handleVersionUpdate(e) {
            if(e.data.version != this.$store.state.version) {
                this.$store.commit('setRefreshRequired', true);
                this.$toasted.show('An update is available, please refresh', {
                    position: "bottom-right", 
                    duration : null,
                    keepOnHover: true
                });
            }
        },
        //After the users selects a stakeholder, save it to the store and continue to next page
        setStakeholder(stakeholder) {
            this.$store.commit('resetStakeholder');
            if (stakeholder.distribution_lists && stakeholder.distribution_lists.data) {
                stakeholder.distribution_lists = stakeholder.distribution_lists.data;
            }
            this.$store.commit('setStakeholder', stakeholder);
            this.$store.commit('setDistributionListsOriginal', stakeholder.distribution_lists ? stakeholder.distribution_lists : []);

            if (!stakeholder.id) {
                this.$store.commit('setStakeholderIsDirty', true);
                this.$store.commit('setStakeholderIsSet', false);
                this.$store.commit('setStakeholderEmailChecked', false);
            }
            else {
                this.$store.commit('setStakeholderIsDirty', false);
                this.$store.commit('setStakeholderIsSet', true);
                this.$store.commit('setStakeholderEmailChecked', true);
            }
        },
        saveStakeholderAndEngagement() {
            this.processing = true;
            const stakeholder = _.cloneDeep(this.$store.state.stakeholder);
            if (this.getStakeholderSubscriptionStatus(stakeholder, this.$store.state.project, 'email', this.useSydMetroWest) === null) {
                this.setStakeholderSubscription(stakeholder, 0, this.$store.state.project, 'email', this.useSydMetroWest);
            }

            if (this.useSydMetroWest) {
                if (!this.getStakeholderSubscriptions(stakeholder, this.$store.state.project, true).filter((subscription) => {
                    return this.getSydMetroSubscriptionMethods().includes(subscription.method);
                }).length) {
                    this.resetStakeholderSubscriptions(stakeholder, this.$store.state.project, true);
                }
            }
            else if (this.useRMS) {
                if (!this.getStakeholderSubscriptions(stakeholder, this.$store.state.project, false, true).filter((subscription) => {
                    return this.getRMSSubscriptionMethods().includes(subscription.method);
                }).length) {
                    this.resetStakeholderSubscriptions(stakeholder, this.$store.state.project, false, true);
                }
            }

            this.$store.commit('setStakeholder', stakeholder);

            return this.$store.dispatch('saveStakeholder')
            .then(() => {
                return this.$store.dispatch('saveEngagement')
            }).then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                if (this.areDoorknocking) {
                    this.$router.push('/doorknock/extra');
                }
                else if (this.isEvent) {
                        
                    this.$router.push('/eventfeedback/extra');
                }
                else if (this.areCalling) {
                    this.$router.push('/phonecall/extra');
                }
            }).finally(() => {
                this.processing = false;
            });
        },
        parseGoogleMapsData(place) {
            let addressObj = {};
            if (place && place.address_components) {
                place.address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        if (typeof this.componentMap[type] === 'undefined') {
                            return;
                        }
                        addressObj[this.componentMap[type]] = component[this.componentForm[type]];
                    });
                });
                return addressObj;
            }
            return null;
        }
    },
    created() {
        this.isAlive = true;
    },
    destroyed() {
        this.isAlive = false;
    }
}
