<template>
<div>
    <page-heading>
        <template slot="default">
            Doorknocking: {{ communityEvent.name }}
        </template>
        <template slot="subHeading">
            <em>Total doorknocks so far: {{attendance}}</em>
        </template>
    </page-heading>

    <counter @attendance="updateAttendance"></counter>

    <b-row align-h="center" >
        <b-col xs="12" md="10" lg="10" xl="10">
            <card-button
            title="Finished"
            variant="primary"
            :onClick="()=>{ this.$router.push('/') }">
            </card-button>
        </b-col>
    </b-row>

    <footer-button
    title="Add feedback"
    :onClick="()=>{ this.$router.push('/doorknock/search') }"
    >
    </footer-button>
</div>
</template>

<script>
import Counter from '@/components/Counter';

export default {
    components: {
        counter: Counter
    },
    data() {
        return {
            attendance: 0
        }
    },
    methods: {
        updateAttendance(newAttendance) {
            this.attendance = newAttendance;
        }
    }
}
</script>
