export default function() {
    return {
        'environment': env('NODE_ENV', ''),

        //urls
        'baseAPIUrl': env('VUE_APP_BASE_API_URL', ''),
        'loginUrl': env('VUE_APP_LOGIN_URL','auth/login'),
        'getProjectsUrl': env('VUE_APP_PROJECTS_URL', 'projects'),
        'getStakeholdersUrl': env('VUE_APP_GET_STAKEHOLDERS_URL', 'stakeholders'),
        'storeStakeholderUrl': env('VUE_APP_STORE_STAKEHOLDER_URL', 'stakeholders'),
        'updateStakeholderUrl': env('VUE_APP_UPDATE_STAKEHOLDER_URL', 'stakeholders'),
        'mergeStakeholderUrl': env('VUE_APP_MERGE_STAKEHOLDER_URL', 'stakeholders/merge'),
        'storeEngagementUrl': env('VUE_APP_STORE_ENGAGEMENT_URL', 'engagements'),
        'updateEngagementUrl': env('VUE_APP_UPDATE_ENGAGEMENT_URL', 'engagements'),
        'getEventsUrl': env('VUE_APP_GET_EVENTS', 'events'),
        'patchIncrementEventUrl': env('VUE_APP_PATCH_INCREMENTS_EVENT', 'events'),
        'patchDecrementEventUrl': env('VUE_APP_PATCH_DECREMENT_EVENT', 'events'),
        'getPortalUrl': env('VUE_APP_GET_PORTAL_URL', 'portal'),
        'baseCMSUrl': env('VUE_APP_BASE_CMS_URL', ''),
        'createEventUrl': env('VUE_APP_CREATE_EVENT_URL', 'clients/{client}/projects/{project}/events/create'),
        'updateEventUrl': env('VUE_APP_UPDATE_EVENT_URL', 'events'),
        'storeEventUrl': env('VUE_APP_STORE_EVENT_URL', 'events'),
        'positionPingUrl': env('VUE_APP_POSITION_PING_URL', 'users/position'),
        'getUsersUrl': env('VUE_APP_GET_USERS_URL', 'users'),
        'getDistributionListsUrl': env('VUE_APP_GET_DISTRIBUTION_LISTS_URL', 'distributionlists'),
        'getFormUrl': env('VUE_APP_GET_FORM_URL', 'forms'),
        'formSubmissionUrl': env('VUE_APP_SUBMIT_FORM_URL', 'formservice/submission'),

        // services
        'sentryDsn': env('VUE_APP_SENTRY_DSN', null),
        'sentryEnabled': env('VUE_APP_SENTRY_ENABLED', 1),

        //iframe
        'targetOrigin': env('VUE_APP_TARGET_ORIGIN', ''),

        //maps
        'mapsKey': env('VUE_APP_MAPS_KEY', ''),
        'initialMapZoomLevel': 6,
        'activeMapZoomLevel': 19,

        //echo
        'eventEchoHost': env('VUE_APP_EVENT_ECHO_HOST', ''),
        'eventEchoVersionChannel': env('VUE_APP_EVENT_ECHO_VERSION_CHANNEL', 'canvas'),
        'eventCounterEchoChannel1': env('VUE_APP_EVENT_COUNTER_ECHO_CHANNEL_1', 'eventChannel'),
        'eventCounterEchoBroadcast1': env('VUE_APP_EVENT_COUNTER_ECHO_BROADCAST_1', '.broadcast'),
        'eventCounterEchoChannel2': env('VUE_APP_EVENT_COUNTER_ECHO_CHANNEL_2', 'ca_api_database_eventChannel'),
        'eventCounterEchoBroadcast2': env('VUE_APP_EVENT_COUNTER_ECHO_BROADCAST_2', 'BroadcastEventUpdated'),
        'eventCounterEchoChannelPrivate': env('VUE_APP_EVENT_COUNTER_ECHO_CHANNEL_PRIVATE', 'project-'),
        'eventCounterEchoBroadcastPrivate': env('VUE_APP_EVENT_COUNTER_ECHO_BROADCAST_PRIVATE', '.BroadcastEventUpdated'),

        'eventPollInterval': env('VUE_APP_EVENT_POLL_INTERVAL', 20000),
        'eventCreationEnabled': env('VUE_APP_EVENT_CREATION_ENABLED', 1),

        'geoWatcherInterval': env('VUE_APP_GEO_WATCHER_INTERVAL', 20000),

        'sydMetroWestEnabled': env('VUE_APP_SYD_METRO_ENABLED', 0),
        'sydMetroWestProjectId': env('VUE_APP_SYD_METRO_WEST_PROJECT_ID', 0),

        'rmsEnabled': env('VUE_APP_RMS_ENABLED', 0),
        'rmsProjectId': env('VUE_APP_RMS_PROJECT_ID', 0),
        
        //version
        'refreshVersion': env('VUE_APP_REFRESH_VERSION', null),

        'issues': [
            {
                id: env('VUE_APP_ISSUES_CONSTRUCTION_ID', 0),
                text: 'Construction'
            },
            {
                id: env('VUE_APP_ISSUES_TRAFFIC_ID', 0),
                text: 'Traffic and transport'
            },
            {
                id: env('VUE_APP_ISSUES_NOISE_ID', 0),
                text: 'Noise and vibration'
            },
            {
                id: env('VUE_APP_ISSUES_BIODIVERSITY_ID', 0),
                text: 'Biodiversity'
            },
            {
                id: env('VUE_APP_ISSUES_AIR_QUALITY_ID', 0),
                text: 'Air quality'
            },
            {
                id: env('VUE_APP_ISSUES_HEALTH_ID', 0),
                text: 'Health and safety'
            },
            {
                id: env('VUE_APP_ISSUES_CYCLE_ID', 0),
                text: 'Shared cycle and pedestrian pathways'
            },
            {
                id: env('VUE_APP_ISSUES_FLORA_ID', 0),
                text: 'Flora and fauna'
            },
            {
                id: env('VUE_APP_ISSUES_ABORIGINAL_ID', 0),
                text: 'Aboriginal and non-aboriginal heritage'
            },
            {
                id: env('VUE_APP_ISSUES_PROPERTY_ID', 0),
                text: 'Property and land use'
            },
            {
                id: env('VUE_APP_ISSUES_WASTE_ID', 0),
                text: 'Waste and contamination'
            },
            {
                id: env('VUE_APP_ISSUES_GROUNDWATER_ID', 0),
                text: 'Groundwater, surface water and flooding'
            },
            {
                id: env('VUE_APP_ISSUES_SUSTAINABILITY_ID', 0),
                text: 'Sustainability, climate change and greenhouse gas'
            },
        ]
    }
}

function env(envName, defaultValue) {
    if (typeof process.env[envName] == 'undefined') {
        return defaultValue;
    }
    if (!isNaN(process.env[envName])) {
        return Number(process.env[envName]);
    }
    return process.env[envName];
}
