var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"mapRef",attrs:{"center":_vm.center,"map-type-id":"hybrid","options":{
        disableDefaultUI: false,
        streetViewControl: false
    },"tilt":0,"zoom":_vm.zoomLevel}},[_c('gmap-cluster',{on:{"click":function($event){return _vm.clusterClickEvent($event)}}},_vm._l((_vm.mapMarkers),function(m,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":m.position},nativeOn:{"click":function($event){return _vm.markerClickEvent(m)}}},[_c('font-awesome-icon',{style:({ color: m.color }),attrs:{"icon":"map-marker-alt","size":"4x"}})],1)}),1),_c('gmap-info-window',{attrs:{"opened":_vm.showWindow,"position":_vm.windowPosition,"options":{
            pixelOffset: {
                width: 0,
                height: -35
            }
        }},on:{"closeclick":function($event){_vm.showWindow=false}}},[(_vm.activeMarker)?_vm._t("marker-window",null,null,_vm.activeMarker):_vm._e(),(_vm.activeCluster)?_vm._t("cluster-window",null,null,_vm.activeCluster):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }