<template>
<div>
    <b-row align-h="center" class="text-left">
        <b-col sm="10" align-self="end">
            <map-location-selector v-model="address"></map-location-selector>
        </b-col>
    </b-row>

    <div>
        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <b-form-group>
                    <label for="location">Location name</label>
                    <b-form-input
                        id="location"
                        v-model="localLocation"
                        placeholder="Enter location name here"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row align-h="center" class="text-left">
            <b-col sm="2">
                <b-form-group
                    label="Unit #:"
                    label-for="unit-no"
                >
                    <b-form-input
                    id="unit-no"
                    v-model="address.address_unit_no"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group
                    label="Street #:"
                    label-for="street-no"
                >
                    <b-form-input
                    id="street-no"
                    v-model="address.address_street_no"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Street name:"
                    label-for="street-name"
                >
                    <b-form-input
                    id="street-name"
                    v-model="address.address_street_name"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="5">
                <b-form-group
                    label="Suburb:"
                    label-for="suburb"
                >
                    <b-form-input
                    id="suburb"
                    v-model="address.address_suburb"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Postcode:"
                    label-for="postcode"
                >
                    <b-form-input
                    id="postcode"
                    v-model="address.address_postcode"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="State:"
                    label-for="state"
                >
                    <b-form-input
                    id="state"
                    v-model="address.address_state"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group
                    label="Country:"
                    label-for="country"
                >
                    <b-form-input
                    id="country"
                    v-model="address.address_country"
                    type="text"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import CardButton from '@/components/CardButton.vue';
import MapLocationSelector from '@/components/MapLocationSelector.vue'
export default {
    components: {
        'card-button': CardButton,
        'map-location-selector': MapLocationSelector
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {}
            }
        },
        location: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            address: this.value,
            localLocation: this.location
        }
    },
    watch: {
        address: {
            handler(newVal) {
                this.$emit('input', newVal);
            },
            deep: true,
        },
        value: function(newVal){
            this.address = newVal;
        },
        localLocation: function(newVal){
            this.$emit('locationChanged', newVal);
        },
        location: function(newVal) {
            this.localLocation = newVal;
        }
    }
}
</script>