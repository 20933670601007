<template>
    <div>
        <b-navbar class="nav" toggleable="xl" type="dark">
            <b-navbar-brand class="nav-icon">
                <img
                    class="d-inline-block align-middle"
                    src="../assets/ca-logo-new-72x72-white.png"
                    alt="CA"
                    width="36"
                    height="36"
                />
            </b-navbar-brand>
            <b-nav-item v-if="publicMode">
                <b-navbar-brand>
                    {{project.name}}: {{communityEvent.name}}
                </b-navbar-brand>
            </b-nav-item>
            <b-nav-item-dropdown v-if="!publicMode">
                <template slot="button-content">
                    <b-navbar-brand>
                        Canvass: {{project.name}}
                    </b-navbar-brand>
                </template>
                <b-dropdown-item to="/">Home</b-dropdown-item>
                <b-dropdown-divider v-if="$store.state.projects.length"></b-dropdown-divider>
                <b-dropdown-item
                    v-for="(project, i) in $store.state.projects"
                    v-bind:key="i"
                    @click.prevent="setProject(project)"
                >{{project.short_name}}</b-dropdown-item>
                <b-dropdown-item v-if="moreProjectsAvailable" @click.prevent="moreProjects">
                    <em>View more</em>
                </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-navbar-toggle v-if="!publicMode" target="nav-collapse"></b-navbar-toggle>

            <b-collapse v-if="!publicMode" id="nav-collapse" is-nav>
                <b-navbar-nav class="horizontal-collapse">
                    <template v-for="method in navigationMethods">
                        <b-nav-item
                            v-if="!method.dropdown"
                            v-bind:key="method.text"
                            :to="method.route"
                            class="outcome-nav"
                        >
                            <h4 v-if="method.icon">
                                <font-awesome-icon :icon="method.icon" :title="method.text"></font-awesome-icon>
                            </h4>
                            {{method.text}}
                        </b-nav-item>
                        <b-nav-item-dropdown v-else v-bind:key="method.text" class="outcome-nav">
                            <template slot="button-content">
                                <h4 v-if="method.icon">
                                    <font-awesome-icon :icon="method.icon" :title="method.text"></font-awesome-icon>
                                </h4>
                                {{method.text}}
                            </template>
                            <b-dropdown-item
                                v-for="dropdown in method.dropdown"
                                v-bind:key="dropdown.text"
                                :to="dropdown.route"
                            >{{dropdown.text}}</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </template>
                    <b-nav-item @click.prevent="signout()" class="outcome-nav hide-off-collapse">
                        <h4>
                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                        </h4>Sign out
                    </b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto hide-on-collapse">
                    <b-nav-item-dropdown right>
                        <!-- Using 'button-content' slot -->
                        <template slot="button-content">
                            <em>{{ $store.state.user ? $store.state.user.name: 'User' }}</em>
                        </template>
                        <b-dropdown-item @click.prevent="signout()">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AppNavigation",
    computed: {
        ...mapState(["project"]),
        canChangeProject() {
            return this.$store.state.projects.length > 1;
        },
        moreProjectsAvailable() {
            return (
                this.$store.state.projectsPagination &&
                this.$store.state.projectsPagination.last_page > 1
            );
        }
    },
    methods: {
        signout() {
            localStorage.removeItem("user");
            this.$store.commit("setClient", null);
            this.$store.commit("setProject", null);
            this.$store.commit("setDistributionLists", []);
            this.$store.commit("setProjects", []);
            this.$store.commit("setUser", null);
            this.$router.push("/login");
        },
        moreProjects() {
            this.$store.commit("setProject", null);
            let user = JSON.parse(localStorage.getItem("user"));
            delete user.data.project;
            localStorage.setItem("user", JSON.stringify(user));
            this.$router.push("/project");
        },
        setProject(project) {
            this.$store.commit("setProject", project);
            let user = JSON.parse(localStorage.getItem("user"));
            user.data.project = project;
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.dispatch('getDistributionLists');
            this.$router.push("/").catch(() => {});
        }
    }
};
</script>

<style lang="scss">
.nav {
    background-color: #000;
}
.nav a {
    cursor: pointer;
}
.navbar {
    padding: 0 1rem 0 0;
}
.navbar > :not(.nav-icon) {
    padding: 0.5rem 0 0.5rem 0;
}
.nav-icon {
    align-self: stretch;
    background: #3569e0;
    padding: 0 1rem 0 1rem;
    display: flex;
    align-items: center;
    margin: 0;
}
.horizontal-collapse {
    flex-direction: row;
    justify-content: space-evenly;
}
.outcome-nav .dropdown-toggle,
.outcome-nav .dropdown-item {
    white-space: normal;
}
.outcome-nav h4 {
    display: inline-block;
    margin: 0;
    font-size: 1.2rem;
}
@media (max-width: 1199.98px) {
    .hide-on-collapse {
        display: none;
    }
    .outcome-nav h4 {
        display: block;
        font-size: 2rem;
    }
    .outcome-nav a {
        font-size: 1rem;
        padding-bottom: 0;
        padding-top: 0;
    }
    .outcome-nav {
        flex-basis: 0;
    }
}
@media (min-width: 1200px) {
    .hide-off-collapse {
        display: none;
    }
}
</style>

