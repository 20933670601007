<template>
<a v-if="linkAddress" :href="linkAddress" :target="openLinkInNewTab ? '_blank' : '_self'">
    <b-card no-body class="text-center my-2 card-button">
        <div class="card-body text-light" v-bind:class="{
            [background]:true,
            'bg-dark':false,
            'card-button-disabled':disabled
        }">
            <h4><slot>{{title}}</slot></h4>
        </div>
    </b-card>
</a>
<b-card v-else no-body @click.prevent="clickIfNotDisabled()" class="text-center my-2 card-button">
    <div class="card-body text-light" v-bind:class="{
        [background]:true,
        'bg-dark':false,
        'card-button-disabled':disabled
    }">
        <h4><slot>{{title}}</slot></h4>
    </div>
</b-card>
</template>

<script>
export default({
    props: {
        title: String,
        variant: {
            type: String,
            default: 'secondary'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: () => {}
        },
        linkAddress: {
            type: String,
            default: ""
        },
        openLinkInNewTab: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        background() {
            return 'bg-' + this.variant;
        }
    },
    methods: {
        clickIfNotDisabled() {
            if (!this.disabled) {
                this.onClick();
            }
        }
    },
});
</script>

<style scoped>
.card-button {
    height: calc(100% - 16px);
    border: none;
}
.card-body {
    padding: 0.75rem;
}
.card-button-disabled {
    opacity: 0.7;
}
.card-body h4 {
    user-select: none;
}
.card-body h4, .card-body p {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
.card-body p {
    font-size: 0.7rem;
}
.card-body.bg-primary:not(.card-button-disabled):hover,.card-body.bg-primary:not(.card-button-disabled):focus {
    background-color: #1a8a58 !important;
}
.card-body.bg-secondary:not(.card-button-disabled):hover,.card-body.bg-secondary:not(.card-button-disabled):focus {
    background-color: #0062cc !important;
}
.card-body.bg-danger:not(.card-button-disabled):hover,.card-body.bg-danger:not(.card-button-disabled):focus {
    background-color: #c82333 !important;
}
.card-body.bg-cancel {
    background-color: #6c757d !important;
}
.card-body.bg-cancel:not(.card-button-disabled):hover,.card-body.bg-cancel:not(.card-button-disabled):focus {
    background-color: #5a6268 !important;
}
.bg-ca {
    background-color: #346ae2 !important;
}
.card-body.bg-ca:not(.card-button-disabled):hover, .card-body.bg-ca:not(.card-button-disabled):focus {
    background-color: #346ae2 !important;
}
</style>

