<template>
<div>
    <b-container>
        <div class="wrapper-page">

            <div class="text-center">
                <img srcset="../assets/ca-logo-2021-640x256.png 2x" src="../assets/ca-logo-2021-320x128.png" width="320" height="128" alt="Community Analytics" class="img-responsive" style="margin: 0 auto">
            </div>

            <form @submit.prevent="login()" class="form-horizontal m-t-20 col-md-6 col-sm-8 col-xs-12" id="loginForm" action="#">

                <div class="form-group">
                    <div class="col-xs-12">
                        <input id="email" v-model="email" autocomplete="username" type="email" class="form-control" name="email" value="" placeholder="Email" autofocus>
                        <i class="md md-account-circle form-control-feedback l-h-34"></i>
                        <span v-if="errorMessage.length" class="help-block">
                            <strong>{{ errorMessage }}</strong>
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <input id="password" autocomplete="current-password" v-model="password" type="password" class="form-control" name="password" placeholder="Password">
                        <i class="md md-vpn-key form-control-feedback l-h-34"></i>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <div class="checkbox checkbox-primary">
                            <input style="margin-left: 0;" v-model="remember_me" type="checkbox" name="remember" id="remember">
                            <label for="remember">
                                Remember me
                            </label>
                        </div>
                    </div>
                </div>

                <p class="small text-justify"><strong>Terms of use:</strong> The information contained within is the sole property of subscription holder and is commercial in confidence. By checking this box and signing in you are acknowledging that you are an authorised Government or Company representative. No other parties are entitled to proceed past this point and all traffic is monitored.</p>

                <div class="form-group text-right m-t-20">
                    <div class="col-xs-12">
                        <card-button variant="ca" :onClick="login">Log in</card-button>
                    </div>
                </div>

                <div class="text-center form-group m-t-30" v-if="forgotEnabled">
                    <a href="#"><i class="fa fa-lock m-r-5"></i> Forgot your
                        password?</a>
                </div>

                <!--hidden submit button because html is awful -->
                <input type="submit" 
                    style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                    tabindex="-1" />
            </form>
        </div>
    </b-container>
</div>
</template>

<script>
import qs from 'qs';
export default {
    data() {
        return {
            loginUrl: this.$config.baseAPIUrl + this.$config.loginUrl,
            email: '',
            password: '',
            remember_me: false,
            errorMessage: '',
            forgotEnabled: false
        }
    },
    methods: {
        login() {
            const request = {
                email: this.email,
                password: this.password,
                remember_me: (this.remember_me ? 1 : 0 )
            };

            this.$http.post(this.loginUrl, qs.stringify(request)).then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));

                this.signin(response.data);

                // if project returned on login, that is the active (and only) project
                if (response.data.data.project) {
                    this.$router.push('/');
                } else {
                    this.$router.push('/project');
                }

            }).catch(() => {
                this.errorMessage = "These credentials do not match our records";
            })
        }
    },
    created() {
        document.title = "CA Canvass";
    }
}
</script>

<style lang="scss" scoped>
.wrapper-page {
    padding-top: 7.5%;
    margin: 0 auto;
}

.img-responsive {
    display: block;
    max-width: 360px;
    height: auto;
}

#loginForm {
    margin: 0 auto;
    padding: 1.0rem;
}

.dark {
    background: #181f2f;
    color: #FFF;
    min-height: 100%;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #a94442
}
</style>
