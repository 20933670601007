<template>
<b-container>
    <spinner v-if="processing"></spinner>

    <div v-if="possibleStakeholders.length">
        <page-heading :displayBack="true">
            Please select a stakeholder from the list below
        </page-heading>

        <card-list :items="possibleStakeholders" keyName="id" :selectItem="selectStakeholder">
            <template v-slot="possibleStakeholder">
                <h4 v-if="possibleStakeholder.first_name || possibleStakeholder.last_name">
                    {{possibleStakeholder.first_name}} {{possibleStakeholder.last_name}}
                </h4>
                <h4 v-else-if="possibleStakeholder.business_name">
                    {{possibleStakeholder.business_name}}
                </h4>
                <h4 v-else>
                    Anonymous
                </h4>
                <p v-if="possibleStakeholder.email">
                    {{possibleStakeholder.email}}
                </p>
                <p v-else>
                    No email given
                </p>
                <p v-if="possibleStakeholder.phone || possibleStakeholder.mobile_phone || possibleStakeholder.home_phone">
                    {{possibleStakeholder.phone ? possibleStakeholder.phone : (possibleStakeholder.mobile_phone ? possibleStakeholder.mobile_phone : possibleStakeholder.home_phone)}}
                </p>
                <p v-else>
                    No phone number given
                </p>
                <p v-if="addressToShortString(possibleStakeholder)">
                    {{addressToShortString(possibleStakeholder)}}
                </p>
                <p v-else>
                    No address given
                </p>
            </template>
        </card-list>

        <pagination v-if="possibleStakeholdersPagination" class="mt-3" :pagination="possibleStakeholdersPagination" @pageChange="pageChange"></pagination>
    </div>

    <page-heading v-else-if="!processing" :displayBack="true" :goBack="()=>$router.back()">
        There are no stakeholders available
    </page-heading>

    <footer-button v-if="!processing"
        :title="createButtonText"
        :onClick="() => {selectStakeholder($store.state.searchStakeholder)}"
        >
        </footer-button>
</b-container>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import CardList from "@/components/CardList.vue";
import Pagination from '@/components/Pagination.vue';
import { mapState } from 'vuex';

export default {
    components: {
        "card-list": CardList,
        'spinner': Spinner,
        'pagination': Pagination
    },
    props: {
        name: String,
        email: String,
        phone: String,
        address: Object,
        nextUrl: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        ...mapState([
            'possibleStakeholders',
            'stakeholderIsSet',
            'possibleStakeholdersPagination'   
        ]),
        createButtonText() {
            switch (this.engagement.outcome) {
                case 'Not home / unable to access':
                    return 'Not sure / not home';
                default:
                    return 'Create new';
            }
        }
    },
    methods: {
        //After the users selects a stakeholder, save it to the store and continue to next page
        selectStakeholder(stakeholder) {
            if (! stakeholder.id) {
                stakeholder = Object.assign(stakeholder, this.address);
            }
            this.setStakeholder(stakeholder);

            if (this.areDoorknocking) {
                if ((this.engagement.outcome == 'Not home / unable to access' || 
                    Object.keys(stakeholder).length) && !this.useRMS) {

                    this.saveStakeholderAndEngagement();
                } 
                else {
                    this.$router.push({ 
                        name: 'stakeholder-edit',
                        params:{
                            prefilled: {
                                name: this.name,
                                email: this.email,
                                phone: this.phone,
                                address: this.address
                            }
                        }
                    });
                }
            }
            else if (this.areCalling) {
                if (this.stakeholder.id) {
                    this.saveStakeholderAndEngagement();
                }
                else {
                    this.$router.push({ 
                        name: 'stakeholder-edit',
                        params:{
                            prefilled: {
                                name: this.name,
                                email: this.email,
                                phone: this.phone,
                                address: this.address
                            }
                        }
                    });
                }
            }
            else if (this.isEvent) {
                    
                if (this.stakeholder.id) {
                    this.saveStakeholderAndEngagement();
                }
                else if (Object.keys(this.nextUrl).length) {
                    this.$router.push({ 
                        name: 'stakeholder-edit',
                        params:{
                            prefilled: {
                                name: this.name,
                                email: this.email,
                                phone: this.phone,
                                address: this.address
                            },
                            nextUrl: this.nextUrl
                        }
                    });
                }
                else {
                    this.$router.push('/stakeholder/edit');
                }
            }
        },
        pageChange(page) {
            this.processing = true;
            this.$store.dispatch('getStakeholdersByPagination', page).finally(() => {
                this.processing = false;
            })
        }
    },
    created() {
        this.$store.commit('resetEngagement', this.engagement)
    }
}
</script>

<style scoped>
.card-body {
    padding: 0.75rem;
}
.card-body h4, .card-body p {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}
.card-body p {
    font-size: 0.7rem;
}
</style>
