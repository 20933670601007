<template>
<div>
    <spinner v-if="processing"></spinner>
    <page-heading v-if="eventsList.length">
        Please select {{nameUsesAn ? 'an' : 'a'}} {{eventName}} from the list below
    </page-heading>
    <page-heading v-else-if="processing">
        Loading...
    </page-heading>
    <page-heading v-else>
        There are no {{eventName}}s scheduled today
    </page-heading>
    <card-list 
        :items="eventsList" 
        :selectItem="selectEvent" 
        keyName="id"
        :useEditLink="true"
        :editItem="editEvent"
        :usePublicMode="!areDoorknocking && !areCountingEventAttendance"
        :publicSignIn="publicSignIn">
        <template v-slot="event">
            <h4>{{event.name}}</h4>
            <p>Start: {{event.start | moment('LLL')}}</p>
            <p>End: {{event.end | moment('LLL')}}</p>
            <p>{{event.location}}</p>
        </template>
    </card-list>
    <footer-button v-if="!processing && $config.eventCreationEnabled" :onClick="createEvent">
        Create {{eventName}}
    </footer-button>
</div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import CardList from '@/components/CardList.vue';
import { mapState } from 'vuex';
export default {
    components: {
        'card-list': CardList,
        'spinner': Spinner
    },
    data() {
        return {
            eventName: 'event',
            nameUsesAn: true,
        }
    },
    computed: {
        ...mapState([
            'possibleEvents'
        ]),
        eventsList() {
            return this.possibleEvents;
        }
    },
    methods: {
        selectEvent(event) {
            this.$store.commit('setEvent', event);
            if (event.form_id) {
                this.$store.dispatch('getForm', event.form_id);
            }
        },
        editEvent(event) {
            this.$store.commit('setEvent', event);
        },
        createEvent() {

        },
        publicSignIn(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setPublicMode', true);
            this.$router.push({ 
                name: 'community-signin'
            });
        }
    }
}
</script>
