<template>
    <b-container class="engagement-method">
        <page-heading :displayBack="false">How are you canvassing?</page-heading>

        <card-list :items="navigationMethods" keyName="route" :selectItem="(method) => {$router.push(method.route)}">
            <template v-slot="method">
                <h4>{{ method.text }}</h4>
            </template>
        </card-list>

    </b-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
export default {
    name: 'EngagementMethod',
    components: {
        "card-list": CardList
    },
    beforeRouteLeave (to, from, next) {
        /*
            catch user trying to navigate back to summary / extra information page, and block the attempt
            console.log('beforeRouteLeave', to, from, next);
        */

        if (to.path === '/phonecall/extra' || to.path === '/doorknock/extra') {
            next(false);

            return;
        }

        next();
    },
    created() {
        this.$store.commit('resetStore');
    }
}

</script>
