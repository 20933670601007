import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

import Layout from './views/Layout';
import Login from './views/Login';
import ProjectSelect from './views/ProjectSelect';
import ErrorPage from './views/ErrorPage';

import PageGroup from './views/PageGroup';
import EngagementMethod from './views/EngagementMethod';

import DoorknockSelect from './views/DoorknockSelect';
import DoorknockLocation from './views/DoorknockLocation';
import DoorknockCounter from './views/DoorknockCounter';
import DoorknockSearch from './views/DoorknockSearch';

import PhonecallNumber from './views/PhonecallNumber';
import PhonecallOutcome from './views/PhonecallOutcome';
import ExtraInformation from './views/ExtraInformation';

import EventCountSelect from './views/EventCountSelect';
import EventCounter from './views/EventCounter';

import EventFeedbackSelect from './views/EventFeedbackSelect';
import EventFeedbackSearch from './views/EventFeedbackSearch';

import StakeholderEdit from './views/StakeholderEdit';
import StakeholderSelect from './views/StakeholderSelect';
import StakeholderMap from './views/StakeholderMap';
import EngagementEdit from './views/EngagementEdit';
import Subscribe from './views/Subscribe';
import EventNotes from './views/EventNotes';
import EventCreate from './views/EventCreate';
import EventEdit from './views/EventEdit';
import PublicSignIn from './views/PublicSignIn';
import FormSubmit from './views/FormSubmit';

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/project',
            name: 'project',
            component: ProjectSelect
        },
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: EngagementMethod
                },
                {
                    path: '/doorknock',
                    // name: 'doorknock',
                    component: PageGroup,
                    children: [
                        {
                            path: '',
                            component: DoorknockSelect
                        },
                        {
                            path: 'counter',
                            component: DoorknockCounter,
                            beforeEnter(to, from , next) {
                                if (!store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        },
                        {
                            path: 'map',
                            name: 'doorknock-map',
                            component: DoorknockLocation,
                            beforeEnter(to, from , next) {
                                if (!store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        },
                        {
                            path: 'extra',
                            component: ExtraInformation,
                            beforeEnter(to, from , next) {
                                if (!store.state.stakeholder.id || !store.state.engagement.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        },
                        {
                            path: 'search',
                            component: DoorknockSearch,
                            beforeEnter(to, from , next) {
                                if (!store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        }
                    ]
        
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    // component: () => import(/* webpackChunkName: "about" */ './views/DoorknockLocation.vue')
                },
                {
                    path: '/phonecall',
                    component: PageGroup,
                    children: [
                        {
                            path: '',
                            component: PhonecallNumber
                        },
                        {
                            path: 'outcome',
                            component: PhonecallOutcome
                        },
                        {
                            path: 'extra',
                            component: ExtraInformation
                        }
                    ]
        
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    // component: () => import(/* webpackChunkName: "about" */ './views/DoorknockLocation.vue')
                },
                {
                    path: '/eventcount',
                    component: PageGroup,
                    children: [
                        {
                            path: '',
                            component: EventCountSelect
                        },
                        {
                            path: 'counter',
                            component: EventCounter,
                            beforeEnter(to, from , next) {
                                if (!store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        }
                    ]
                },
                {
                    path: '/eventfeedback',
                    component: PageGroup,
                    children: [
                        {
                            path: '',
                            component:EventFeedbackSelect
                        },
                        {
                            path: 'search',
                            component: EventFeedbackSearch,
                            beforeEnter(to, from , next) {
                                if (!store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        },
                        {
                            path: 'extra',
                            component: ExtraInformation,
                            beforeEnter(to, from , next) {
                                if (!store.state.stakeholder.id || !store.state.stakeholder.id || !store.state.event.id) {
                                    return returnToPreviousPage(from, next);
                                }
                                return next();
                            }
                        }
                    ]
                },
                {
                    path: '/stakeholder/edit',
                    name: 'stakeholder-edit',
                    component: StakeholderEdit,
                    props: true
                },
                {
                    path: '/stakeholder/select',
                    name: 'stakeholder-select',
                    component: StakeholderSelect,
                    props: true
                },
                {
                    path: '/stakeholder/map',
                    name: 'stakeholder-map',
                    component: StakeholderMap,
                    beforeEnter(to, from , next) {
                        if (!store.state.stakeholder.id) {
                            return returnToPreviousPage(from, next);
                        }
                        return next();
                    }
                },
                {
                    path: '/engagement/edit',
                    name: 'engagement-edit',
                    component: EngagementEdit,
                    props: true
                },
                {
                    path: '/subscribe',
                    name: 'subscribe',
                    component: Subscribe
                },
                {
                    path: '/event/notes',
                    name: 'event-notes',
                    component: EventNotes,
                    props: true,
                    beforeEnter(to, from , next) {
                        if (!store.state.event.id) {
                            return returnToPreviousPage(from, next);
                        }
                        return next();
                    }
                },
                {
                    path: '/event/create',
                    name: 'event-create',
                    component: EventCreate,
                    props: true
                },
                {
                    path: '/event/edit',
                    name: 'event-edit',
                    component: EventEdit,
                    props: true
                },
                {
                    path: '/community-signin',
                    name: 'community-signin',
                    component: PublicSignIn
                },
                {
                    path: '/form-submit',
                    name: 'form-submit',
                    component: FormSubmit
                }
            ]
        }, 
        {
            path: '/error',
            component: ErrorPage
        },
        {
            path: '*',
            component: ErrorPage
        }
    ]
})

//From https://jasonwatmore.com/post/2018/07/06/vue-vuex-jwt-authentication-tutorial-example
router.beforeEach((to, from, next) => {
    let nextPage = null;

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);

    const loggedIn = localStorage.getItem('user') ? true : false;
    let projectSet = false;

    if (loggedIn) {
        try {
            const token = JSON.parse(localStorage.getItem('user'));
            if (typeof token === 'object' && typeof token.data === 'object') {
                projectSet = token.data.project;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    if (authRequired && !loggedIn) {
        nextPage = '/login';
    } else if (loggedIn && !projectSet && to.path != "/project") {
        nextPage = '/project';
    } else if (!authRequired && loggedIn) {
        nextPage = '/';
    }

    const publicModePages = ['/community-signin'];
    if (publicModePages.includes(to.path)) {
        store.commit('setPublicMode', true);
    }
    else {
        store.commit('setPublicMode', false);
    }

    if(store.state.refreshRequired) {
        store.commit('setRefreshRequired', false);
        if (nextPage) {
            window.location = location.origin + nextPage;
        }
        else{
            window.location = to.fullPath;
        }
    }
    else {
        if (nextPage) {
            return next(nextPage);
        }
        else{
            return next();
        }
    }
});

function returnToPreviousPage(from, next){
    Vue.prototype.$toasted.show('Unable to load this page directly', {
        position: 'top-right',
        duration: null,
        keepOnHover: true,
        action : {
            text : 'Close',
            onClick : (e, toastObject) => {
                toastObject.goAway(0);
            }
        },
    });
    if (from.matched.length) {
        return next(false);
    }
    return next(from);
}

export default router;