<template>
    <b-container>
        <spinner v-if="processing"></spinner>
        <page-heading>Edit {{localEvent.name}} notes</page-heading>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <label for="summary">Summary</label>
                <b-form-textarea
                    id="summary"
                    v-model="localEvent.summary"
                    placeholder="Enter event summary here"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <label for="stakeholder-comments">Stakeholder comments</label>
                <b-form-textarea
                    id="stakeholder-comments"
                    v-model="localEvent.notes"
                    placeholder="Enter stakeholder comments here"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <b-row align-h="center" class="text-left">
            <b-col sm="10">
                <label for="team-response">Team response</label>
                <b-form-textarea
                    id="team-response"
                    v-model="localEvent.team_response"
                    placeholder="Enter team response here"
                ></b-form-textarea>
            </b-col>
        </b-row>

        <footer-button
            title="Continue"
            :onClick="saveEvent"
        >
        </footer-button>
    </b-container>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import _ from 'lodash';
export default {
    components: {
        'spinner': Spinner
    },
    props: {
        nextUrl: {
            type: Object,
            default: (() => {
                return {};
            })
        },
        prefilled: {
            type: Object,
            default: (() => {
                return {};
            })
        }
    },
    data() {
        return {
            localEvent: {}
        }
    },
    methods: {
        saveEvent() {
            this.processing = true;
            this.$store.commit('setEvent', this.localEvent);
            this.$store.dispatch('saveEvent').then(() => {
                if (!this.isAlive) {
                    Promise.reject('Promise cancelled')
                }
                if (Object.keys(this.nextUrl).length) {
                    this.$router.push(this.nextUrl);
                }
                else if (this.engagement.method == this.EVENT_FEEDBACK_METHOD.method ||
                    this.engagement.method == this.PRESENTATION_METHOD.method ||
                    this.engagement.method == this.COMMUNITY_INFORMATION_SESSION_METHOD.method) {
                    this.$router.push('/eventfeedback/extra');
                }
            }).finally(() => {
                this.processing = false;
            });
        }
    },
    created() {
        if (Object.keys(this.prefilled).length) {
            Object.assign(this.localEvent, this.prefilled);
        }
        else {
            this.localEvent = _.clone(this.communityEvent);
        }
    }
}

</script>
