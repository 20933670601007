<template>
    <b-container class="stakeholder-map">

        <page-heading>Stakeholder</page-heading>

        <GmapMap
            :center="currentLocation"
            map-type-id="hybrid"
            :options="{
                disableDefaultUI: false
            }"
            ref="mapRef"
            :zoom="zoomLevel"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="currentLocation=m.position"
            />
        </GmapMap>

        <b-container>
            <div class="b-rpw">
                <b-col>
                    <h2 class="address">{{ stakeholderAddress }}</h2>
                </b-col>
            </div>

 
        </b-container>

        <!--<div class="footer-button">
            <b-button block size="lg" @click="$router.go(-1)" variant="primary">Go back</b-button>
        </div>-->

        <footer-button
        title="Go back"
        :onClick="() => {$router.go(-1)}"
        >
        </footer-button>

    </b-container>

</template>

<script>

import {gmapApi} from 'vue2-google-maps'

export default {
    data() {
        return {
            currentLocation:{
                lat: 0,
                lng: 0
            },
            markers: [],
            map: null,
            zoomLevel: 15
        }
    },
    mounted : function() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;

            // map.panTo({lat: 1.38, lng: 103.80})
            this.loadAddress();
            this.bindEventListeners();

        });

    },
    computed: {
        google: gmapApi,
    },
    methods: {
        bindEventListeners: function() {
            const geocoder = new this.google.maps.Geocoder;

            let center = new this.google.maps.LatLng(this.currentLocation.lat, this.currentLocation.lng);

            var myMarker = new this.google.maps.Marker({
                position: center,
                draggable: true,
                map: this.map,
                animation: this.google.maps.Animation.DROP
            });

            this.google.maps.event.addListener(myMarker, 'click', () => {
                this.map.setCenter(myMarker.getPosition()); // Set map center to marker position
                this.updatePosition(myMarker.getPosition().lat(), myMarker.getPosition().lng()); // update position display
                this.geocodeLatLng(geocoder, this.map);
            });

        },

        // the smooth zoom function
        smoothZoom: function(map, max, cnt) {
            if (cnt >= max) {
                return;
            } else {
                let z = this.google.maps.event.addListener(map, 'zoom_changed', () => {
                    this.google.maps.event.removeListener(z);
                    this.smoothZoom(map, max, cnt + 1);
                });

                setTimeout(function() {
                    map.setZoom(cnt)
                }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
            }
        },

        loadAddress: function() {
            const geocoder = new this.google.maps.Geocoder();

            geocoder.geocode({
                'address': this.stakeholderAddress
            }, (results, status) => {
                if (status === 'OK') {
                    this.currentLocation.lat = results[0].geometry.location.lat();
                    this.currentLocation.lng = results[0].geometry.location.lng();
                    this.markers.push({
                        position: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        }
                    })
                }
            });
        },
    }
}

</script>

<style lang="scss" scoped>
@import "../variables.scss";
.vue-map-container {
    height: $map-height;
    margin: $margin-default;
}
</style>
