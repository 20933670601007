<script>
import EventSelector from '@/components/EventSelector'
export default {
    extends: EventSelector,
    computed: {
        eventsList() {
            return this.possibleEvents.filter((event) => {
                return event.type != 'Doorknock';
            });
        }
    },
    methods: {
        selectEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            if (event.form_id) {
                this.$store.dispatch('getForm', event.form_id);
            }
            this.$router.push('/eventfeedback/search');
        },
        editEvent(event) {
            this.$store.commit('setEvent', event);
            this.$store.commit('setEventIsDirty', false);
            this.$router.push({ 
                name: 'event-edit', 
                params: { 
                    nextUrl: {
                        path: '/eventfeedback',
                    }
                }
            });
        },
        createEvent() {
            this.$router.push({ 
                name: 'event-create', 
                params: { 
                    nextUrl: {
                        path: '/eventfeedback/search',
                    }
                }
            });
        }
    },
    created() {
        this.$store.commit('resetStore');

        this.processing = true;

        if (this.useSydMetroWest) {
            this.$store.commit('setEngagementMethod', 'Presentation');
            this.eventName = 'presentation';
            this.nameUsesAn = false;
            this.$store.dispatch('getTodaysEvents', 'Presentation').finally(() => {
                this.processing = false;
            })
        }
        else {
            this.$store.commit('setEngagementMethod', 'Event');
            this.$store.dispatch('getTodaysEvents').finally(() => {
                this.processing = false;
            })
        }
    }
}
</script>
